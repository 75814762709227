export default (namespace) => ({
  yes: {
    id: `${namespace}.yes`,
    defaultMessage: 'yes',
  },
  no: {
    id: `${namespace}.no`,
    defaultMessage: 'no',
  },
  status: {
    id: `${namespace}.status`,
    defaultMessage: 'I am',
  },
  owner: {
    id: `${namespace}.owner`,
    defaultMessage: 'owner',
  },
  renter: {
    id: `${namespace}.renter`,
    defaultMessage: 'renter',
  },
  propertyType: {
    id: `${namespace}.propertyType`,
    defaultMessage: 'Of',
  },
  house: {
    id: `${namespace}.house`,
    defaultMessage: 'house',
  },
  apartment: {
    id: `${namespace}.apartment`,
    defaultMessage: 'apartment',
  },
  duplex: {
    id: `${namespace}.duplex`,
    defaultMessage: 'duplex',
  },
  propertyArea: {
    id: `${namespace}.propertyArea`,
    defaultMessage: 'Area',
  },
  locationType: {
    id: `${namespace}.locationType`,
    defaultMessage: 'My house is in an urban center',
  },
  notCombustibleMaterials: {
    id: `${namespace}.notCombustibleMaterials`,
    defaultMessage:
      'I confirm that my house is not built in more than 25% with combustible materials',
  },
  notUninhabitedArea: {
    id: `${namespace}.notUninhabitedArea`,
    defaultMessage: 'I confirm that my house is not in an uninhabited area',
  },
  insuranceStart: {
    id: `${namespace}.insuranceStart`,
    defaultMessage: 'I want my home insurance to start on',
  },
  givenName: {
    id: `${namespace}.givenName`,
    defaultMessage: 'Name',
  },
  surname: {
    id: `${namespace}.surname`,
    defaultMessage: '1<sup>er</sup> apellido',
  },
  secondSurname: {
    id: `${namespace}.secondSurname`,
    defaultMessage: 'Second name',
  },
  personalIdNumberPrefix: {
    id: `${namespace}.personalIdNumberPrefix`,
    defaultMessage: ' ',
  },
  personalIdNumber: {
    id: `${namespace}.personalIdNumber`,
    defaultMessage: 'ID document and number',
  },
  birthDate: {
    id: `${namespace}.birthDate`,
    defaultMessage: 'Date of Birth',
  },
  phone: {
    id: `${namespace}.phone`,
    defaultMessage: 'Mobile phone',
  },
  otherPhone: {
    id: `${namespace}.otherPhone`,
    defaultMessage: 'Other phone',
  },
  email: {
    id: `${namespace}.email`,
    defaultMessage: 'Email',
  },
  emailOptional: {
    id: `${namespace}.emailOptional`,
    defaultMessage: 'Email (opcional)',
  },
  street: {
    id: `${namespace}.street`,
    defaultMessage: 'Street address',
  },
  streetExtended: {
    id: `${namespace}.streetExtended`,
    defaultMessage: 'Insured housing address',
  },
  streetType: {
    id: `${namespace}.streetType`,
    defaultMessage: 'Street type',
  },
  number: {
    id: `${namespace}.number`,
    defaultMessage: 'Street number',
  },
  stairs: {
    id: `${namespace}.stairs`,
    defaultMessage: 'Stairs',
  },
  floor: {
    id: `${namespace}.floor`,
    defaultMessage: 'Floor',
  },
  letter: {
    id: `${namespace}.letter`,
    defaultMessage: 'Letter',
  },
  postCode: {
    id: `${namespace}.postCode`,
    defaultMessage: 'Postcode',
  },
  city: {
    id: `${namespace}.city`,
    defaultMessage: 'City',
  },
  province: {
    id: `${namespace}.province`,
    defaultMessage: 'Province',
  },
  country: {
    id: `${namespace}.country`,
    defaultMessage: 'Country',
  },
  bankAccount: {
    id: `${namespace}.bankAccount`,
    defaultMessage:
      'Introduce tu IBAN. Si lo deseas, lo puedes copiar y pegar.',
  },
  marketingConsent: {
    id: `${namespace}.marketingConsent`,
    defaultMessage:
      'I want to receive information about other product and services from BNP Paribas Cardif accordingly to their private policy conditions',
  },
  licenceConsent: {
    id: `${namespace}.licenceConsent`,
    defaultMessage: 'I accept the {link} and I subscribe it',
  },
  gdprConsent: {
    id: `${namespace}.gdprConsent`,
    defaultMessage: 'I accept data protection condition',
  },
  isOptional: {
    id: `${namespace}.isOptional`,
    defaultMessage: ' (Optional)',
  },
  calle: {
    id: `${namespace}.calle`,
    defaultMessage: 'Calle',
  },
  avenida: {
    id: `${namespace}.avenida`,
    defaultMessage: 'Avenida',
  },
  plaza: {
    id: `${namespace}.plaza`,
    defaultMessage: 'Plaza',
  },
  carretera: {
    id: `${namespace}.carretera`,
    defaultMessage: 'Carretera',
  },
  paseo: {
    id: `${namespace}.paseo`,
    defaultMessage: 'Passeo',
  },
  travesia: {
    id: `${namespace}.travesia`,
    defaultMessage: 'Travesia',
  },
  period: {
    id: `${namespace}.period`,
    defaultMessage: 'Period',
  },
  WEEK: {
    id: `${namespace}.WEEK`,
    defaultMessage: 'Every day',
  },
  WEEKEND: {
    id: `${namespace}.WEEKEND`,
    defaultMessage: 'Only during the weekends',
  },
  purchasePrice: {
    id: `${namespace}.purchasePrice`,
    defaultMessage: 'The price of my mobile is',
  },
  PRICE_0_200: {
    id: `${namespace}.PRICE_0_200`,
    defaultMessage: '0-200€',
  },
  PRICE_200_500: {
    id: `${namespace}.PRICE_200_500`,
    defaultMessage: '201€-500€',
  },
  PRICE_500_750: {
    id: `${namespace}.PRICE_500_750`,
    defaultMessage: '501€-750€',
  },
  PRICE_750_1000: {
    id: `${namespace}.PRICE_750_1000`,
    defaultMessage: '751€-1000€',
  },
  PRICE_1000: {
    id: `${namespace}.PRICE_1000`,
    defaultMessage: '>1000€',
  },
  purchaseDate: {
    id: `${namespace}.purchaseDate`,
    defaultMessage: 'I have bought it',
  },
  acknowledgement: {
    id: `${namespace}.acknowledgement`,
    defaultMessage: 'Confirmation of licence agreement',
  },
  contactName: {
    id: `${namespace}.contactName`,
    defaultMessage: 'Nombre y apellidos',
  },
  contactDate: {
    id: `${namespace}.contactDate`,
    defaultMessage: '¿Cuándo te gustaría contratar este seguro?',
  },
  insuranceStatus: {
    id: `${namespace}.insuranceStatus`,
    defaultMessage: '¿Ya tienes este seguro con otra compañía?',
  },
  insuranceNo: {
    id: `${namespace}.insuranceNo`,
    defaultMessage: 'No',
  },
  insuranceYes: {
    id: `${namespace}.insuranceYes`,
    defaultMessage: 'Sí',
  },
  date: {
    id: `${namespace}.date`,
    defaultMessage: '¿Cuándo te gustaría contratar este seguro? (opcional)',
  },
});
