import {
  always,
  applySpec,
  curryN,
  F,
  pipe,
  prop,
  replace,
  toUpper,
} from 'ramda';
import formatDate from 'date-fns/fp/format';
import parseISO from 'date-fns/fp/parseISO';

const toInsurant = applySpec({
  streetType: pipe(prop('streetType'), toUpper),
  birthDate: pipe(prop('birthDate'), parseISO, formatDate('yyyy-MM-dd')),
  personalId: applySpec({
    personalIdType: prop('personalIdNumberPrefix'),
    personalId: prop('personalIdNumber'),
  }),
  bankAccount: pipe(prop('bankAccount'), replace(/\s/g, ''), toUpper),
  givenName: prop('givenName'),
  surname: prop('surname'),
  secondSurname: prop('secondSurname'),
  phonePrefix: prop('phonePrefix'),
  phone: prop('phone'),
  otherPhonePrefix: prop('otherPhonePrefix'),
  otherPhone: prop('otherPhone'),
  email: prop('email'),
  street: prop('street'),
  number: prop('number'),
  stairs: prop('stairs'),
  floor: prop('floor'),
  letter: prop('letter'),
  postCode: prop('postCode'),
  city: prop('city'),
  province: prop('province'),
  country: prop('country'),
});

// eslint-disable-next-line import/prefer-default-export
export const toApiFormat = curryN(2, (priceData, values) =>
  applySpec({
    insurant: toInsurant,
    product: always(priceData),
    licenceConsent: prop('licenceConsent'),
    gdprConsent: prop('gdprConsent'),
    marketingConsent: F,
  })(values)
);
