export default (namespace) => ({
  hireOnline: {
    id: `${namespace}.hireOnline`,
    defaultMessage: 'Hire online',
  },
  submit: {
    id: `${namespace}.submit`,
    defaultMessage: 'Submit',
  },
  callUsFree: {
    id: `${namespace}.callUsFree`,
    defaultMessage: 'Call us toll free (900 533 691)',
  },
});
