import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import TextInput from '../../../components/FormV2/TextInput';
import LocalizedFormError from './LocalizedError';
import SelectBox from '../../../components/Form/SelectBox/SelectBox';
import { getValidationState } from '../utils';
import Flags from '../../../components/Flags/Flags';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
};

const PhoneInputField = ({ id, name }) => (
  <div>
    <div className="flex">
      <div className="w-[105px]">
        <Field name={`${name}Prefix`} value="+34">
          {({ field }) => (
            <SelectBox
              {...field}
              disabled
              type="pref"
              image={<Flags country="ES" />}
              options={[{ value: '+34' }]}
            />
          )}
        </Field>
      </div>
      <div className="ml-5 grow">
        <Field name={name}>
          {({ field, meta }) => (
            <TextInput
              {...field}
              showStatusIcon
              id={id}
              type="tel"
              validationState={getValidationState(meta)}
            />
          )}
        </Field>
      </div>
    </div>
    <Field name={name}>
      {({ meta }) => (
        <LocalizedFormError
          validationState={getValidationState(meta)}
          message={meta.error}
        />
      )}
    </Field>
  </div>
);

PhoneInputField.propTypes = propTypes;

export default PhoneInputField;
