import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';

import { messages } from '../../intl';
import TitleText from '../../../components/TitleText';
import RadioButton from '../components/RadioButton';
import SummaryItem from '../components/SummaryItem';
import Tooltip from '../../../components/Tooltip';
import {
  Sport1,
  Sport2,
  Sport3,
  Sport4,
} from '../../../components/Icons/Icons';
import OrderSummary from '../components/OrderSummary';
import { validate, toApiFormat } from './schema';
import { querySportInsurancePrice } from '../../api/products/service';
import { addExtraFieldToPriceData } from '../utils';

const propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
};

const SportInsurance = ({ onSubmitSuccess }) => {
  const intl = useIntl();

  const onPriceUpdateRequest = useCallback(
    (values) => querySportInsurancePrice(toApiFormat(values)),
    []
  );

  return (
    <Formik
      validateOnMount
      validateOnChange
      onSubmit={(values) =>
        querySportInsurancePrice(toApiFormat(values))
          .then(addExtraFieldToPriceData(values))
          .then(onSubmitSuccess)
      }
      validate={validate}
      initialErrors={{ period: 'required' }}
      initialValues={{ coverages: { '229-coverage-fallecimiento': true } }}
    >
      <Form>
        <div className="max-w-3xl mx-auto">
          <div className="my-8">
            <TitleText
              text={intl.formatMessage(messages.widgetSport.firstTitle)}
            />
          </div>

          <div className="max-w-md mx-auto my-8 grid gap-3">
            <div>
              <RadioButton
                label={intl.formatMessage(messages.fieldLabels.WEEK)}
                name="period"
                value="WEEK"
              />
            </div>
            <div>
              <RadioButton
                label={intl.formatMessage(messages.fieldLabels.WEEKEND)}
                name="period"
                value="WEEKEND"
              />
            </div>
          </div>

          <div className="my-8">
            <TitleText
              text={intl.formatMessage(messages.widgetSport.secondTitle)}
            />
          </div>

          <div>
            <SummaryItem
              disabled
              name="coverages.229-coverage-fallecimiento"
              title="FALLECIMIENTO ACCIDENTAL"
              desc="15.000€ en caso de fallecimiento accidental, para que disfrutes de tu deporte con la mayor tranquilidad y responsabilidad."
              icon={<Sport1 alt="FALLECIMIENTO ACCIDENTAL icon" />}
              tooltip={
                <Tooltip
                  tooltipDesc={intl.formatMessage(
                    messages.tooltips['229-coverage-fallecimiento']
                  )}
                />
              }
            />

            <SummaryItem
              name="coverages.229-coverage-incapacidad-permanente"
              title="INCAPACIDAD PERMANENTE ACCIDENTAL"
              desc="Protégete ante los imprevistos con un capital de 15.000€ que te ayudarán a mantener tu calidad de vida en caso de Incapacidad Permanente."
              icon={<Sport2 alt="INCAPACIDAD PERMANENTE ACCIDENTAL icon" />}
              tooltip={
                <Tooltip
                  tooltipDesc={intl.formatMessage(
                    messages.tooltips['229-coverage-incapacidad-permanente']
                  )}
                />
              }
            />

            <SummaryItem
              name="coverages.229-coverage-incapacidad-temporal"
              title="INCAPACIDAD TEMPORAL"
              desc="¡Mantén tu ritmo de vida! Recibe una indemnización diaria de 30€ si por culpa de un accidente deportivo tienes que estar de baja laboral."
              icon={<Sport3 alt="INCAPACIDAD TEMPORAL icon" />}
              tooltip={
                <Tooltip
                  tooltipDesc={intl.formatMessage(
                    messages.tooltips['229-coverage-incapacidad-temporal']
                  )}
                />
              }
            />

            <SummaryItem
              name="coverages.229-coverage-accesorios"
              dependsOn={['coverages.229-coverage-incapacidad-temporal']}
              title="ACCESORIOS"
              desc="Reloj, pulsómetro, teléfono, tus nuevas deportivas ultraligeras… son muchos los accesorios que utilizamos para hacer deporte. ¡Protégelo todo! en caso de accidente recibirás una compensación de 300€ para que repongas lo que se dañe."
              icon={<Sport4 alt="ACCESORIOS icon" />}
              tooltip={
                <Tooltip
                  tooltipDesc={intl.formatMessage(
                    messages.tooltips['229-coverage-accesorios']
                  )}
                />
              }
            />
          </div>
        </div>

        <OrderSummary onPriceUpdateRequest={onPriceUpdateRequest} />
      </Form>
    </Formik>
  );
};

SportInsurance.propTypes = propTypes;

export default SportInsurance;
