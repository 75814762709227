import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { fieldCommonClasses } from '../settings';
import ErrorText from '../ErrorText';
import Label from '../Label';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email']),
  value: PropTypes.string,
  showValid: PropTypes.oneOf(['valid', 'invalid', 'initial']),
  errorText: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  inputComponent: PropTypes.func,
  className: PropTypes.string,
  specType: PropTypes.string,
};

const defaultProps = {
  type: 'text',
  label: undefined,
  value: '',
  showValid: 'initial',
  placeholder: undefined,
  inputComponent: 'input',
  className: undefined,
  specType: undefined,
  onBlur: undefined,
};

const TextField = ({
  name,
  label,
  type,
  value,
  errorText,
  showValid,
  placeholder,
  inputComponent: Input,
  onChange,
  specType,
  className,
  onBlur,
  ...props
}) => (
  <div className="text-field w-full">
    <label className={cx(fieldCommonClasses, className)}>
      {label && <Label text={label} />}
      <div
        className={cx(
          'relative w-full h-fit',
          'after:font-icons after:text-[10px] after:pointer-events-none after:p-2 after:absolute',
          'after:right-1.5 after:top-1/2 after:-translate-y-1/2',
          {
            'after:content-[""] after:text-clr-green':
              showValid === 'valid' && value !== '',
            'after:content-[""] after:text-clr-red-error':
              showValid === 'invalid',
          }
        )}
      >
        <Input
          {...props}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          className={cx(
            'text-sm text-clr-black leading-[22px] p-2.5 w-full border-2 rounded focus:ring-transparent',
            {
              'border-clr-gray-light': showValid !== 'invalid',
              'border-clr-red-error': showValid === 'invalid',
              'focus:border-clr-green hover:border-clr-green':
                showValid !== 'invalid',
              'focus:border-clr-red-error hover:border-clr-red-error':
                showValid === 'invalid',
            },
            'transition-colors ease-in-out delay-150'
          )}
        />
      </div>
    </label>
    {specType !== 'opt-box' && errorText && <ErrorText text={errorText} />}
  </div>
);
TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;

export default TextField;
