import { reduce, times, always, concat } from 'ramda';

const CHARS = [/[eE]/, /[sS]/];
const DELIMITER = [' '];
const NUMBER = /\d/;

const concatAll = reduce(concat, []);
const createNumbers = (timesX) => times(always(NUMBER), timesX);

const makeIbanMask = concatAll([
  CHARS,
  createNumbers(2),
  DELIMITER,
  createNumbers(4),
  DELIMITER,
  createNumbers(4),
  DELIMITER,
  createNumbers(2),
  DELIMITER,
  createNumbers(10),
]);

export default makeIbanMask;
