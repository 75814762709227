export default (namespace) => ({
  [`${namespace}.title`]: 'Datos personales',
  [`${namespace}.contactTitle`]: 'Datos de contacto',
  [`${namespace}.residenceTitle`]: 'Datos adicionales',
  [`${namespace}.bankCodeTitle`]: 'Datos de pago',
  [`${namespace}.bankCodeContent`]:
    'Le informamos de que sus datos serán tratados por Cardif para la contratación y gestión de su seguro, conforme a la cláusula de protección de datos del mismo y la Política de Privacidad',
  [`${namespace}.confirmationTitle`]: 'Por favor confirme lo siguiente',
  [`${namespace}.summaryBoxTitle`]: 'Resumen de tus coberturas:',
  [`${namespace}.summaryBoxTotalTitle`]: 'Precio Total: ',
  [`${namespace}.summaryBoxTotalPerMonth`]: '{total} / mes',
  [`${namespace}.summaryBoxAddTitle`]: '¿Necesitas ayuda?',
  [`${namespace}.summaryBoxAddPhone`]: 'Llámanos al 900 533 691',
  [`${namespace}.summaryBoxAddPhoneCarrefour`]: 'Llámanos al 900 533 698',
});
