import { o, mergeAll, map } from 'ramda';

const regexNamespaceFromFilename = /(\w+).namespace.js$/;
const mergeNamespacedTranslation = (context) =>
  o(
    mergeAll,
    map((key) => {
      const namespace = regexNamespaceFromFilename.exec(key)[1];
      // TODO Detect overwritten namespaces
      return context(key).default(namespace);
    })
  );

export default mergeNamespacedTranslation;
