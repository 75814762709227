import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  label: PropTypes.node,
  input: PropTypes.node.isRequired,
};

const defaultProps = {};

const FormRow = ({ label, input }) => (
  <div className="max-w-[320px] mx-auto md:max-w-none w-full md:flex mb-4 items-start">
    <div className="md:grow md:text-right md:pr-4">{label}</div>
    <div className="md:w-[430px]">{input}</div>
  </div>
);

FormRow.propTypes = propTypes;
FormRow.defaultProps = defaultProps;

export default FormRow;
