import React from 'react';
import PropTypes from 'prop-types';

import Container from '../../../components/Container';
import PFLogo from '../../../assets/Icons/PFLogo.svg';
import NavLink from '../../../components/NavLink';
import PhoneLink from '../../../components/PhoneLink';
import Text from '../../../components/Text';
import {
  BNP_COOKIE_POLICY_URL,
  BNP_PRIVACY_POLICY_URL,
} from '../../../constants';

const propTypes = {
  phoneLinkRef: PropTypes.shape(),
};

const defaultProps = {
  phoneLinkRef: undefined,
};

const Footer = ({ phoneLinkRef }) => (
  <div className="py-6">
    <Container>
      <div className="sm:flex items-center">
        <div className="max-w-3/12">
          <a href="https://www.cetelem.es/" title="Ir a la página de inicio">
            <img
              className="h-24 mx-auto w-56"
              src={PFLogo}
              alt="cetelem logo"
            />
          </a>
        </div>
        <nav className="flex-1 flex md:flex-row flex-col items-center justify-center gap-4">
          <NavLink link="/mapa-web" label="Mapa web" />
          <NavLink link={BNP_COOKIE_POLICY_URL} label="Política de cookies" />
          <NavLink
            link={BNP_PRIVACY_POLICY_URL}
            label="Política de privacidad"
          />
          <NavLink link="/aviso-legal" label="Aviso legal" />
        </nav>
        <div className="text-right mt-4">
          <Text
            align="right"
            size="bodyDefault"
            text="¿Necesitas ayuda?"
            color="gray"
          />
          <Text
            align="right"
            size="bodyDefault"
            text="Llámanos al"
            color="gray"
          />
          <div className="inline-block">
            <PhoneLink
              phoneLinkRef={phoneLinkRef}
              phone="tel:900533691"
              label="900533691"
            />
          </div>
        </div>
      </div>
    </Container>
  </div>
);

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
