import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  text: PropTypes.node.isRequired,
  align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  size: PropTypes.oneOf(['subTitle', 'bodyDefault', 'bodyS', 'textS']),
  color: PropTypes.oneOf(['black', 'green', 'gray', 'darkGray']),
  transform: PropTypes.oneOf(['none', 'uppercase']),
  className: PropTypes.string,
};

const defaultProps = {
  align: 'left',
  size: 'bodyDefault',
  transform: 'none',
  color: 'black',
};

const Text = ({ text, align, size, transform, className, color }) => (
  <p
    className={cx(
      {
        'text-left': align === 'left',
        'text-right': align === 'right',
        'text-center': align === 'center',
        'text-justify': align === 'justify',
        'text-clr-black': color === 'black',
        'text-clr-green': color === 'green',
        'text-clr-gray': color === 'gray',
        'text-clr-gray-dark': color === 'darkGray',
        'text-base lg:text-2xl leading-6 lg:leading-[26px] font-medium font-ubuntu':
          size === 'subTitle',
        'text-base leading-6 font-medium': size === 'bodyDefault',
        'text-sm leading-[22px] font-medium': size === 'bodyS',
        'text-xs leading-4 font-medium': size === 'textS',
        'text-none': transform === 'none',
        uppercase: transform === 'uppercase',
      },
      className
    )}
  >
    {text}
  </p>
);

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;
