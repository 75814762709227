import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useIntl } from 'react-intl';
import addMonths from 'date-fns/addMonths';

import { queryMobileInsurancePrice } from '../../api/products/service';
import { messages } from '../../intl';
import TitleText from '../../../components/TitleText';
import OrderSummary from '../components/OrderSummary';
import { Mobile1, Mobile2, Mobile3 } from '../../../components/Icons';
import Tooltip from '../../../components/Tooltip';
import DatePicker from '../components/DatePicker';
import SelectBox from '../components/SelectBox';
import SummaryItem from '../components/SummaryItem';
import Checkbox from '../components/Checkbox';
import { toApiFormat } from './schema';
import validate from './_lundegaard/validations';
import { remapErrors } from '../_lundegaard/validator';
import { addExtraFieldToPriceData } from '../utils';
import MissingDateValidator from './MissingDateValidator';

const propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
};

// Extracted from Liferay
const options = [
  {
    value: 'PRICE_0_200',
    label: '0-200€',
  },
  {
    value: 'PRICE_200_500',
    label: '201-500€',
  },
  {
    value: 'PRICE_500_750',
    label: '501-750€',
  },
  {
    value: 'PRICE_750_1000',
    label: '751-1000€',
  },
  {
    value: 'PRICE_1000',
    label: '1001-1750€',
  },
];

const MobileInsuranceForm = ({ onSubmitSuccess }) => {
  const intl = useIntl();
  const today = useMemo(() => new Date(), []);
  const onPriceUpdateRequest = useCallback(
    (values) => queryMobileInsurancePrice(toApiFormat(values)),
    []
  );

  return (
    <Formik
      validateOnMount
      validateOnChange
      onSubmit={(values) =>
        queryMobileInsurancePrice(toApiFormat(values))
          .then(addExtraFieldToPriceData(values))
          .then(onSubmitSuccess)
      }
      initialValues={{
        purchasePrice: 'PRICE_0_200',
        purchaseDate: '',
        coverages: { '228-coverage-dano': true },
      }}
      validate={(values) => remapErrors(validate(values))}
      initialErrors={{ acknowledgement: 'campo ffs' }}
    >
      <Form>
        <div className="max-w-3xl mx-auto">
          <div className="my-8">
            <TitleText
              text={intl.formatMessage(messages.widgetMobile.firstTitle)}
            />
          </div>

          <div className="max-w-xl tracking-tight">
            <div className="py-2">
              <SelectBox
                name="purchasePrice"
                label={intl.formatMessage(messages.fieldLabels.purchasePrice)}
                options={options}
              />
            </div>

            <div className="py-2">
              <DatePicker
                legacyError
                name="purchaseDate"
                customOptions={{
                  maxDate: today,
                  minDate: addMonths(today, -6),
                }}
                label={intl.formatMessage(messages.fieldLabels.purchaseDate)}
                placeholder="DD/MM/AAAA"
              />
            </div>
          </div>

          <div className="my-8">
            <TitleText
              text={intl.formatMessage(messages.widgetMobile.secondTitle)}
            />
          </div>

          <div>
            <SummaryItem
              name="coverages.228-coverage-dano"
              disabled
              title="DAÑO ACCIDENTAL"
              desc="¿Hay algo peor que quedarse sin móvil? Sustituiremos tu teléfono en caso de daño accidental o rotura de pantalla."
              icon={<Mobile1 alt="coverage dano icon" />}
              tooltip={
                <Tooltip
                  tooltipDesc={intl.formatMessage(
                    messages.tooltips['228-coverage-dano']
                  )}
                />
              }
            />

            <SummaryItem
              name="coverages.228-coverage-robo"
              title="ROBO"
              desc="No te podemos prometer que atraparemos al ladrón, pero sí te garantizamos que tendrás un nuevo móvil."
              icon={<Mobile3 alt="coverage robo icon" />}
              tooltip={
                <Tooltip
                  tooltipDesc={intl.formatMessage(
                    messages.tooltips['228-coverage-robo']
                  )}
                />
              }
            />

            <SummaryItem
              name="coverages.228-coverage-recuerdos"
              title="RECUERDOS"
              desc="¿Por qué pasar horas intentando hacer que tu nuevo móvil sea como el antiguo? ¡Ahorra tiempo! Nosotros te ayudamos a recuperar tus contactos, fotos, aplicaciones...para que nada ni nadie estropee tus recuerdos."
              icon={<Mobile2 alt="coverage recuerdos icon" />}
              tooltip={
                <Tooltip
                  tooltipDesc={intl.formatMessage(
                    messages.tooltips['228-coverage-recuerdos']
                  )}
                />
              }
            />
          </div>

          <div className="max-w-xl xl:ml-24 md:ml-16">
            <MissingDateValidator />
            <Checkbox
              name="acknowledgement"
              label={intl.formatMessage(messages.fieldLabels.acknowledgement)}
            />
          </div>
        </div>

        <OrderSummary
          hasExcess
          onPriceUpdateRequest={onPriceUpdateRequest}
          footnote="some"
          tooltip="La franquicia es la cantidad que pagas al declarar un siniestro de tu dispositivo. Esta cantidad se utiliza para reducir el coste de tu seguro."
        />
      </Form>
    </Formik>
  );
};

MobileInsuranceForm.propTypes = propTypes;

export default MobileInsuranceForm;
