import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from '../../../intl';
import FormRow from '../components/FormRow';
import FormSection from '../components/FormSection';
import Label from '../../../../components/FormV2/Label/Label';
import Text from '../../../../components/Text';
import CheckBox from '../../components/Checkbox';
import IBANField from '../../components/IBANField';
import { BNP_PRIVACY_POLICY_URL } from '../../../../constants';

const propTypes = {};

const BankSection = () => {
  const intl = useIntl();

  return (
    <FormSection
      title={intl.formatMessage(messages.widgetSubscription.bankCodeTitle)}
    >
      <FormRow
        label={
          <Label
            htmlFor="bankAccount"
            html={intl.formatMessage(messages.fieldLabels.bankAccount)}
            tooltip={intl.formatMessage(messages.tooltips.bankAccount)}
          />
        }
        input={<IBANField id="bankAccount" name="bankAccount" />}
      />
      <FormRow
        input={
          <Text
            size="bodyS"
            align="center"
            text={intl.formatMessage(
              messages.widgetSubscription.bankCodeContent
            )}
          />
        }
      />
      <FormRow
        input={
          <CheckBox
            name="licenceConsent"
            label={
              <FormattedMessage
                {...messages.fieldLabels.licenceConsent}
                values={{
                  link: (
                    <a
                      href={BNP_PRIVACY_POLICY_URL}
                      className="text-blue-500"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage
                        {...messages.labelLinks.licenceConsent}
                      />
                    </a>
                  ),
                }}
              />
            }
          />
        }
      />
      <FormRow
        input={
          <CheckBox
            name="gdprConsent"
            label={intl.formatMessage(messages.fieldLabels.gdprConsent)}
          />
        }
      />
    </FormSection>
  );
};

BankSection.propTypes = propTypes;

export default BankSection;
