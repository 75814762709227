import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useIntl } from 'react-intl';

import SelectBoxDesign from '../../../components/Form/SelectBox';
import { getValidationState, maybeLocalizeError } from '../utils';

const propTypes = {
  name: PropTypes.string.isRequired,
};

const SelectBox = ({ name, ...inputProps }) => {
  const intl = useIntl();

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <SelectBoxDesign
          {...inputProps}
          onChange={field.onChange}
          name={field.name}
          value={field.value}
          errorText={
            getValidationState(meta) === 'invalid'
              ? maybeLocalizeError(intl, meta.error)
              : null
          }
        />
      )}
    </Field>
  );
};

SelectBox.propTypes = propTypes;

export default SelectBox;
