import React from 'react';
import PropTypes from 'prop-types';

import './radiobutton.css';

const propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  errorText: PropTypes.string,
};

const RadioButton = ({ onChange, name, label, value, errorText }) => (
  <>
    <div>
      <label>
        <input type="radio" name={name} onChange={onChange} value={value} />
        <span>{label}</span>
      </label>
    </div>
    <div className="pt-1 text-xs text-clr-red-error font-medium">
      {errorText}
    </div>
  </>
);

RadioButton.propTypes = propTypes;

export default RadioButton;
