export default (namespace) => ({
  [`${namespace}.description.227-group-ademas`]:
    'Además, añade coberturas complementarias',
  [`${namespace}.title.227-group-ademas`]: '',
  [`${namespace}.titleSubscription.227-group-ademas`]: '',
  [`${namespace}.description.225-coverage-robo`]:
    'Esperemos que nadie entre en tu casa sin tu permiso, pero si sucede, estarás protegido ante cualquier daño que puedan ocasionar y los objetos que puedan llevarse.',
  [`${namespace}.title.225-coverage-robo`]: 'Robo',
  [`${namespace}.titleSubscription.225-coverage-robo`]: 'Robo',
  [`${namespace}.description.225-coverage-danos-esteticos`]:
    'La estética de tu casa es importante, por eso si se rompe una tubería y hay que pintar, mantendremos el estilo que has querido darle a tu hogar, ¡en toda la estancia!',
  [`${namespace}.title.225-coverage-danos-esteticos`]: 'Daños estéticos',
  [`${namespace}.titleSubscription.225-coverage-danos-esteticos`]:
    'Daños estéticos',
  [`${namespace}.description.225-coverage-roturas`]:
    'En caso de rotura de cristales, encimeras, loza y hasta la vitrocerámica de tu hogar, nos hacemos cargo de los gastos del remplazo, transporte e instalación.',
  [`${namespace}.title.225-coverage-roturas`]: 'Roturas',
  [`${namespace}.titleSubscription.225-coverage-roturas`]: 'Roturas',
  [`${namespace}.description.225-coverage-manitas`]:
    'Colgar un cuadro, montar un mueble, colgar las cortinas nuevas… ¡Que lo haga otro! Ahorra tiempo y problemas llamando a un Manitas Profesional.',
  [`${namespace}.title.225-coverage-manitas`]: 'Manitas',
  [`${namespace}.titleSubscription.225-coverage-manitas`]: 'Manitas',
  [`${namespace}.description.226-coverage-manitas`]:
    'Colgar un cuadro, montar un mueble, colgar las cortinas nuevas… ¡Que lo haga otro! Ahorra tiempo y problemas llamando a un Manitas Profesional.',
  [`${namespace}.title.226-coverage-manitas`]: 'Manitas',
  [`${namespace}.titleSubscription.226-coverage-manitas`]: 'Manitas',
  [`${namespace}.description.226-coverage-robo`]:
    'Esperemos que nadie entre en tu casa sin tu permiso, pero si sucede, estarás protegido ante cualquier daño que puedan ocasionar y los objetos que puedan llevarse.',
  [`${namespace}.title.226-coverage-robo`]: 'Robo',
  [`${namespace}.titleSubscription.226-coverage-robo`]: 'Robo',
  [`${namespace}.description.226-coverage-roturas`]:
    'En caso de rotura de cristales, encimeras, loza y hasta la vitrocerámica de tu hogar, nos hacemos cargo de los gastos del remplazo, transporte e instalación.',
  [`${namespace}.title.226-coverage-roturas`]: 'Roturas',
  [`${namespace}.titleSubscription.226-coverage-roturas`]: 'Roturas',
  [`${namespace}.description.227-coverage-familia`]:
    'La familia es lo primero y queremos ayudarte a darle la atención que merece. Para ello nos hacemos cargo de los costes que se puedan generar (canguro, comidas fuera del hogar…) con 30€ al día adicionales.',
  [`${namespace}.title.227-coverage-familia`]: 'Familia',
  [`${namespace}.titleSubscription.227-coverage-familia`]: 'Familia',
  [`${namespace}.description.227-coverage-hosp-por-enfermedad`]:
    'Hospitalización  por Enfermedad: 70€ por día hospitalizado',
  [`${namespace}.title.227-coverage-hosp-por-enfermedad`]: '',
  [`${namespace}.titleSubscription.227-coverage-hosp-por-enfermedad`]:
    'Hospitalización  por Enfermedad',
  [`${namespace}.description.225-coverage-basico`]:
    'Estarás protegido ante fuego, agua, electricidad y viento. Además contarás con las coberturas de Responsabilidad Civil y Asistencia y Emergencia en el hogar.',
  [`${namespace}.title.225-coverage-basico`]: 'Básico',
  [`${namespace}.titleSubscription.225-coverage-basico`]: 'Básico',
  [`${namespace}.description.226-coverage-basico`]:
    'Estarás protegido ante fuego, agua, electricidad y viento. Además contarás con las coberturas de Responsabilidad Civil y Asistencia y Emergencia en el hogar.',
  [`${namespace}.title.226-coverage-basico`]: 'Básico',
  [`${namespace}.titleSubscription.226-coverage-basico`]: 'Básico',
  [`${namespace}.description.227-coverage-hosp-por-accidente`]:
    'Hospitalización  por Accidente : 70€ por día hospitalizado',
  [`${namespace}.title.227-coverage-hosp-por-accidente`]: '',
  [`${namespace}.titleSubscription.227-coverage-hosp-por-accidente`]:
    'Hospitalización por Accidente',
  [`${namespace}.description.228-coverage-recuerdos`]:
    '¿Por qué pasar horas intentando hacer que tu nuevo móvil sea como el antiguo? ¡Ahorra tiempo! Nosotros te ayudamos a recuperar tus contactos, fotos, aplicaciones...para que nada ni nadie estropee tus recuerdos.',
  [`${namespace}.title.228-coverage-recuerdos`]: 'Recuerdos',
  [`${namespace}.titleSubscription.228-coverage-recuerdos`]: 'Recuerdos',
  [`${namespace}.description.228-coverage-robo`]:
    'No te podemos prometer que atraparemos al ladrón, pero sí te garantizamos que tendrás un nuevo móvil.',
  [`${namespace}.title.228-coverage-robo`]: 'Robo',
  [`${namespace}.titleSubscription.228-coverage-robo`]: 'Robo',
  [`${namespace}.description.229-coverage-incapacidad-permanente`]:
    'Protégete ante los imprevistos con un capital de 15.000€ que te ayudarán a mantener tu calidad de vida en caso de Incapacidad  Permanente.',
  [`${namespace}.title.229-coverage-incapacidad-permanente`]:
    'Incapacidad permanente accidental',
  [`${namespace}.titleSubscription.229-coverage-incapacidad-permanente`]:
    'Incapacidad permanente accidental',
  [`${namespace}.description.229-coverage-accesorios`]:
    'Reloj, pulsómetro, teléfono, tus nuevas deportivas ultraligeras... son muchos los accesorios que utilizamos para hacer deporte. ¡Protégelo todo! en caso de accidente recibirás una compensación de 300€ para que repongas lo que se dañe.',
  [`${namespace}.title.229-coverage-accesorios`]: 'Accesorios',
  [`${namespace}.titleSubscription.229-coverage-accesorios`]: 'Accesorios',
  [`${namespace}.description.228-coverage-dano`]:
    '¿Hay algo peor que quedarse sin móvil? Sustituiremos tu teléfono en caso de daño accidental o rotura de pantalla.',
  [`${namespace}.title.228-coverage-dano`]: 'Daño accidental',
  [`${namespace}.titleSubscription.228-coverage-dano`]: 'Daño accidental',
  [`${namespace}.description.227-coverage-trasporte`]:
    'Sabemos que estar hospitalizado genera muchos gastos extra, por eso queremos ayudarte con los costes de desplazamiento y parking con 30€ al día adicionales.',
  [`${namespace}.title.227-coverage-trasporte`]: 'Transporte',
  [`${namespace}.titleSubscription.227-coverage-trasporte`]: 'Transporte',
  [`${namespace}.description.229-coverage-incapacidad-temporal`]:
    '¡Mantén tu ritmo de vida! Recibe una indemnización diaria de 30€ si por culpa de un accidente deportivo tienes que estar de baja laboral.',
  [`${namespace}.title.229-coverage-incapacidad-temporal`]:
    'Incapacidad temporal',
  [`${namespace}.titleSubscription.229-coverage-incapacidad-temporal`]:
    'Incapacidad temporal',
  [`${namespace}.description.234-coverage-incapacidad-permanente`]:
    'Protégete ante los imprevistos con un capital de 15.000€ que te ayudarán a mantener tu calidad de vida en caso de Incapacidad  Permanente.',
  [`${namespace}.title.234-coverage-incapacidad-permanente`]:
    'Incapacidad permanente accidental',
  [`${namespace}.titleSubscription.234-coverage-incapacidad-permanente`]:
    'Incapacidad permanente accidental',
  [`${namespace}.description.234-coverage-fallecimiento`]:
    '15.000€ en caso de fallecimiento accidental, para que disfrutes de tu deporte con la mayor tranquilidad y responsabilidad.',
  [`${namespace}.title.234-coverage-fallecimiento`]: 'Fallecimiento accidental',
  [`${namespace}.titleSubscription.234-coverage-fallecimiento`]:
    'Fallecimiento accidental',
  [`${namespace}.description.234-coverage-accesorios`]:
    'Reloj, pulsómetro, teléfono, tus nuevas deportivas ultraligeras... son muchos los accesorios que utilizamos para hacer deporte. ¡Protégelo todo! en caso de accidente recibirás una compensación de 300€ para que repongas lo que se dañe.',
  [`${namespace}.title.234-coverage-accesorios`]: 'Accesorios',
  [`${namespace}.titleSubscription.234-coverage-accesorios`]: 'Accesorios',
  [`${namespace}.description.234-coverage-incapacidad-temporal`]:
    '¡Mantén tu ritmo de vida! Recibe una indemnización diaria de 30€ si por culpa de un accidente deportivo tienes que estar de baja laboral.',
  [`${namespace}.title.234-coverage-incapacidad-temporal`]:
    'Incapacidad temporal',
  [`${namespace}.titleSubscription.234-coverage-incapacidad-temporal`]:
    'Incapacidad temporal',
  [`${namespace}.description.229-coverage-fallecimiento`]:
    '15.000€ en caso de fallecimiento accidental, para que disfrutes de tu deporte con la mayor tranquilidad y responsabilidad.',
  [`${namespace}.title.229-coverage-fallecimiento`]: 'Fallecimiento accidental',
  [`${namespace}.titleSubscription.229-coverage-fallecimiento`]:
    'Fallecimiento accidental',
  [`${namespace}.description.227-coverage-covalencia`]:
    'Si se alarga tu estancia en el hospital por encima de 14 días, recibirás una ayuda complementaria de 1.000€',
  [`${namespace}.title.227-coverage-covalencia`]: 'Convalecencia',
  [`${namespace}.titleSubscription.227-coverage-covalencia`]: 'Convalecencia',
});
