import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Container from '../../../components/Container';
import AnimBanner from '../../../components/AnimBanner';
import { messages } from '../../intl';
import Footer from './Footer';
import FeatureCards from './FeatureCards';

const propTypes = {
  variant: PropTypes.oneOf([
    'mobile',
    'hospitalization',
    'sport',
    'subscription',
    'confirmation',
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const defaultProps = {};

const Layout = ({ variant, children }) => {
  const intl = useIntl();
  const footerPhoneRef = useRef(null);
  const disableHeader = useMemo(
    () => variant === 'subscription' || variant === 'confirmation',
    []
  );
  const onHeaderClick = useCallback(() => {
    if (footerPhoneRef.current) {
      footerPhoneRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [footerPhoneRef]);

  return (
    <div className="flex flex-col w-full min-h-screen items-stretch">
      {!disableHeader && (
        <>
          <Container>
            <AnimBanner
              onClick={onHeaderClick}
              btnLabel={intl.formatMessage(messages.buttonLabels.callUsFree)}
              page={variant}
            />
          </Container>
          <Container>
            <FeatureCards />
          </Container>
        </>
      )}
      <div className="grow">{children}</div>
      <Footer phoneLinkRef={footerPhoneRef} />
    </div>
  );
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
