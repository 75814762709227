import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import SubscriptionSummaryDesign from '../../components/SubscriptionSummary/SubscriptionSummary';
import { messages } from '../intl';

const propTypes = {
  coverages: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalPrice: PropTypes.number.isRequired,
};

const SubscriptionSummary = ({ coverages, totalPrice }) => {
  const intl = useIntl();
  const priceFormatter = useMemo(
    () =>
      new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }),
    []
  );

  const localizedCoverages = useMemo(
    () =>
      coverages.map((c) =>
        intl.formatMessage(messages.coverages.titleSubscription[c])
      ),
    [coverages]
  );

  return (
    <SubscriptionSummaryDesign
      coverages={localizedCoverages}
      title={intl.formatMessage(messages.widgetSubscription.summaryBoxTitle)}
      addTitle={intl.formatMessage(
        messages.widgetSubscription.summaryBoxAddTitle
      )}
      addPhone={intl.formatMessage(
        messages.widgetSubscription.summaryBoxAddPhone
      )}
      totalTitle={intl.formatMessage(
        messages.widgetSubscription.summaryBoxTotalTitle
      )}
      totalPerMonth={intl.formatMessage(
        messages.widgetSubscription.summaryBoxTotalPerMonth,
        { total: priceFormatter.format(totalPrice) }
      )}
    />
  );
};

SubscriptionSummary.propTypes = propTypes;

export default SubscriptionSummary;
