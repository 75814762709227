import { equals, either, pipe, map, fromPairs } from 'ramda';

const valueMirror = pipe(
  map((v) => [v, v]),
  fromPairs
);

export const ProductTypes = valueMirror([
  'OWNER',
  'RENTER',
  'HOSPITALIZATION',
  'MOBILE',
  'SPORT',
  'CARREFOUR',
]);

export const isProductOwnerType = equals(ProductTypes.OWNER);
export const isProductRenterType = equals(ProductTypes.RENTER);
export const isProductHospitalizationType = equals(
  ProductTypes.HOSPITALIZATION
);
export const isProductMobileType = equals(ProductTypes.MOBILE);
export const isProductSportType = equals(ProductTypes.SPORT);
export const isProductCarrefourType = equals(ProductTypes.CARREFOUR);

export const ProductCodes = {
  [ProductTypes.OWNER]: 225,
  [ProductTypes.RENTER]: 226,
  [ProductTypes.HOSPITALIZATION]: 227,
  [ProductTypes.MOBILE]: 228,
  [ProductTypes.SPORT]: 229,
  [ProductTypes.CARREFOUR]: 234,
};

export const isProductOwnerCode = equals(ProductCodes.OWNER);
export const isProductRenterCode = equals(ProductCodes.RENTER);
export const isProductHospitalizationCode = equals(
  ProductCodes.HOSPITALIZATION
);
export const isProductMobileCode = equals(ProductCodes.MOBILE);
export const isProductSportCode = equals(ProductCodes.SPORT);
export const isProductCarrefourCode = equals(ProductCodes.CARREFOUR);

export const isProductOwnerOrRenterCode = either(
  isProductOwnerCode,
  isProductRenterCode
);

export const WebTypes = {
  OWNER: '01',
  RENTER: '02',
  HOSPITALIZATION: '03',
  SPORT: '04',
  MOBILE: '05',
  CARREFOUR: '06',
};

export const ClickToCallType = {
  GENOME: 'genome',
};
