import React, { useRef, useState, useCallback, useEffect } from 'react';

import OrderSummaryImpl, { propTypes, defaultProps } from './OrderSummaryImpl';

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 1.0,
};

const OrderSummary = (props) => {
  const containerRef = useRef(null);
  const [visible, setIsVisible] = useState(null);

  const onIntersect = useCallback(([entry]) => {
    setIsVisible(entry.isIntersecting);
  });

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef.current]);

  return (
    <div>
      {!visible && <OrderSummaryImpl {...props} fixed />}
      <OrderSummaryImpl {...props} containerRef={containerRef} />
    </div>
  );
};

OrderSummary.propTypes = propTypes;
OrderSummary.defaultProps = defaultProps;

export default OrderSummary;
