import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Container from '../components/Container';
import NavLink from '../components/NavLink';
import { BNP_COOKIE_POLICY_URL, BNP_PRIVACY_POLICY_URL } from '../constants';
import Layout from '../modules/common/components/Layout';

const SitemapPage = () => (
  <Layout variant="confirmation">
    <HelmetProvider>
      <Helmet>
        <title>Mapa web | Cetelem</title>
      </Helmet>
    </HelmetProvider>
    <Container>
      <div className="max-w-md mx-auto text-center text-clr-black">
        <h1 className="my-12 text-5xl">MAPA WEB</h1>
        <ul className="mr-8 list-disc text-left inline-block">
          <li>
            <NavLink link="#" label="Mapa web" />
          </li>
          <li>
            <NavLink link={BNP_COOKIE_POLICY_URL} label="Política de cookies" />
          </li>
          <li>
            <NavLink
              link={BNP_PRIVACY_POLICY_URL}
              label="Política de privacidad"
            />
          </li>
          <li>
            <NavLink link="/aviso-legal" label="Aviso legal" />
          </li>
        </ul>
      </div>
    </Container>
  </Layout>
);

export default SitemapPage;
