export default (namespace) => ({
  [`${namespace}.flexibilityTitle`]: 'Tú decides',
  [`${namespace}.flexibilityText`]:
    'No pagues por coberturas que no vas a utilizar.',
  [`${namespace}.benefitsTitle`]: 'Más que un seguro',
  [`${namespace}.benefitsText`]:
    'Disfruta de los beneficios que hemos pensado para ti.',
  [`${namespace}.withoutPermanenceTitle`]: 'Sin Permanencia',
  [`${namespace}.withoutPermanenceText`]:
    'Tú eliges hasta cuándo, sin penalizaciones.',
});
