import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  width: PropTypes.oneOf(['full', 'half']),
};

const commonClasses = 'rounded-lg h-2.5 mt-10';

const ProgressBar = ({ width }) => (
  <div className={`${commonClasses} bg-clr-green-light`}>
    <div
      className={cx(commonClasses, {
        'w-full bg-clr-green': width === 'full',
        'w-1/2 bg-clr-green': width === 'half',
      })}
    ></div>
  </div>
);

ProgressBar.propTypes = propTypes;

export default ProgressBar;
