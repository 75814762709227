import React from 'react';
import PropTypes from 'prop-types';

import BaseIcon from './BaseIcon';

export default (name, src) => {
  const component = ({ alt, className }) => (
    <BaseIcon src={src} alt={alt} className={className} />
  );

  component.displayName = name;

  component.propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  return component;
};
