import { combineValidate, validate } from '@validarium/core';
import { isRequired } from '@validarium/validations';

const validations = validate({
  purchasePrice: [isRequired],
  purchaseDate: [isRequired],
  acknowledgement: [isRequired],
});

export default combineValidate(validations);
