import {
  always,
  applySpec,
  equals,
  keys,
  pickBy,
  pipe,
  prop,
  unary,
} from 'ramda';

const PRODUCT_CODE = 229;

export const validate = (values) => {
  const errors = {};

  if (!values.period) {
    errors.period = 'required';
  }

  return errors;
};

export const toApiFormat = applySpec({
  productCode: always(PRODUCT_CODE),
  period: prop('period'),
  coverageCodes: pipe(prop('coverages'), pickBy(unary(equals(true))), keys),
});
