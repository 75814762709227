import createIconComponent from './createIconComponent';
import flexibilitySrc from '../../assets/Icons/flexibility.svg';
import benefitSrc from '../../assets/Icons/benefit.svg';
import withoutPermanenceSrc from '../../assets/Icons/without-permanence.svg';
import mobile1src from '../../assets/Icons/mobile-1.svg';
import mobile2src from '../../assets/Icons/mobile-2.svg';
import mobile3src from '../../assets/Icons/mobile-3.svg';
import woundedSrc from '../../assets/Icons/wounded.svg';
import transportSrc from '../../assets/Icons/transport.svg';
import familySrc from '../../assets/Icons/family.svg';
import watchSrc from '../../assets/Icons/watch.svg';
import fonendoskopSrc from '../../assets/Icons/fonendoskop.svg';
import sport1Src from '../../assets/Icons/sport-1.svg';
import sport2Src from '../../assets/Icons/sport-2.svg';
import sport3Src from '../../assets/Icons/sport-3.svg';
import sport4Src from '../../assets/Icons/sport-4.svg';

export const Flexibility = createIconComponent('Flexibility', flexibilitySrc);
export const Benefit = createIconComponent('Benefit', benefitSrc);
export const Mobile1 = createIconComponent('Mobile1', mobile1src);
export const Mobile2 = createIconComponent('Mobile2', mobile2src);
export const Mobile3 = createIconComponent('Mobile3', mobile3src);
export const Wounded = createIconComponent('Wounded', woundedSrc);
export const Transport = createIconComponent('Transport', transportSrc);
export const Family = createIconComponent('Family', familySrc);
export const Watch = createIconComponent('Watch', watchSrc);
export const Fonendoskop = createIconComponent('Fonendoskop', fonendoskopSrc);
export const Sport1 = createIconComponent('Sport1', sport1Src);
export const Sport2 = createIconComponent('Sport2', sport2Src);
export const Sport3 = createIconComponent('Sport3', sport3Src);
export const Sport4 = createIconComponent('Sport4', sport4Src);
export const WithoutPermanence = createIconComponent(
  'WithoutPermanence',
  withoutPermanenceSrc
);
