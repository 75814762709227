import { o, mergeAll, map } from 'ramda';

const regexLanguageFromPath = /(\w{2}|\w{2}_\w{2})\/index.js$/;
const mergeNamespacedTranslation = (context) =>
  o(
    mergeAll,
    map((key) => {
      const language = regexLanguageFromPath.exec(key)[1];
      return { [language]: context(key).default };
    })
  );

export default mergeNamespacedTranslation;
