import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { allPass, curryN, map, not, pathOr, pipe, split } from 'ramda';

import SummaryItemDesign from '../../../components/SummaryItem';

const propTypes = {
  name: PropTypes.string.isRequired,
  dependsOn: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  dependsOn: undefined,
};

const flippedAllPass = curryN(2, (data, conds) => allPass(conds)(data));

const disableOnDependency = (values, dependsOn) =>
  pipe(
    map(pipe(split('.'), pathOr(false))),
    flippedAllPass(values),
    not
  )(dependsOn);

const MasterWatcher = ({ dependsOn, name, field }) => {
  const { values, setFieldValue } = useFormikContext(name);

  useEffect(() => {
    if (field.value && disableOnDependency(values, dependsOn)) {
      setFieldValue(name, false, true);
    }
  }, [values]);

  return null;
};

MasterWatcher.propTypes = { ...propTypes, field: PropTypes.shape() };

const SummaryItem = ({ name, dependsOn, ...rest }) => (
  <Field name={name}>
    {({ field, form }) => (
      <>
        {dependsOn && (
          <MasterWatcher field={field} name={name} dependsOn={dependsOn} />
        )}
        <SummaryItemDesign
          disabled={dependsOn && disableOnDependency(form.values, dependsOn)}
          {...rest}
          checkedValue={field.value || false}
          onChange={() => form.setFieldValue(name, !field.value)}
          name={field.name}
        />
      </>
    )}
  </Field>
);

SummaryItem.propTypes = propTypes;
SummaryItem.defaultProps = defaultProps;

export default SummaryItem;
