import React from 'react';
import PropTypes from 'prop-types';

import Text from '../Text';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  coverages: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  addTitle: PropTypes.string,
  addPhone: PropTypes.string,
};

const SubscriptionSummaryBox = ({
  children,
  coverages,
  title,
  addPhone,
  addTitle,
}) => (
  <div className="mt-12 xl:mt-32 mb-14 w-[300px] inline-block text-left">
    <div className="mb-2">
      <div className="p-8 bg-clr-green-light">
        <Text size="bodyDefault" align="center" text={title} color="green" />
        <div className="grid gap-4 mt-7">
          {coverages.map((v) => (
            <div key={v} className="flex justify-between items-center">
              <Text size="bodyS" text={v} color="green" className="pr-2" />
              <div>
                <i className="font-icons text-[10px] text-clr-green pl-1 pr-2 pt-2 pb-1.5 bg-white rounded-full">
                  
                </i>
              </div>
            </div>
          ))}
        </div>
      </div>
      {children}
    </div>
    <Text size="bodyDefault" text={addTitle} color="gray" />
    <Text size="bodyDefault" text={addPhone} color="gray" />
  </div>
);

SubscriptionSummaryBox.propTypes = propTypes;

export default SubscriptionSummaryBox;
