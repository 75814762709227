import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: undefined,
};

const BaseIcon = ({ src, alt, className }) => (
  <img src={src} alt={alt} className={className} />
);

BaseIcon.propTypes = propTypes;
BaseIcon.defaultProps = defaultProps;

export default BaseIcon;
