import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useIntl } from 'react-intl';

import { messages } from '../../intl';
import TitleText from '../../../components/TitleText';
import Text from '../../../components/Text';
import SummaryItem from '../components/SummaryItem';
import {
  Family,
  Fonendoskop,
  Transport,
  Watch,
  Wounded,
} from '../../../components/Icons';
import Tooltip from '../../../components/Tooltip';
import OrderSummary from '../components/OrderSummary';
import { queryHospitalizationInsurancePrice } from '../../api/products/service';
import { toApiFormat, validate } from './schema';
import { addExtraFieldToPriceData } from '../utils';

const propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
};

const HospitalizationInsurance = ({ onSubmitSuccess }) => {
  const intl = useIntl();
  const onPriceUpdateRequest = useCallback(
    (values) => queryHospitalizationInsurancePrice(toApiFormat(values)),
    []
  );

  return (
    <Formik
      validateOnMount
      validateOnChange
      initialValues={{ coverages: { '227-coverage-hosp-por-accidente': true } }}
      validate={validate}
      onSubmit={(values) =>
        queryHospitalizationInsurancePrice(toApiFormat(values))
          .then(addExtraFieldToPriceData(values))
          .then(onSubmitSuccess)
      }
    >
      <Form>
        <div className="max-w-3xl mx-auto">
          <div className="my-8">
            <TitleText
              text={intl.formatMessage(messages.widgetMobile.secondTitle)}
            />
            <Text
              className="mt-2"
              size="bodyS"
              align="center"
              text="¡No podemos controlar los imprevistos! Pero sí podemos acompañarte durante el tiempo que estés hospitalizado. Ya sea a consecuencia de un accidente o de una enfermedad, recibirás una indemnización por cada día que pases en el hospital. ¡Selecciona tu cobertura!"
            />
          </div>
          <div>
            <SummaryItem
              name="coverages.227-coverage-hosp-por-accidente"
              desc="HOSPITALIZACIÓN POR ACCIDENTE : 70€ por día hospitalizado"
              icon={<Wounded alt="Hospitalización por Accidente icon" />}
              tooltip={
                <Tooltip
                  tooltipDesc={intl.formatMessage(
                    messages.tooltips['227-coverage-hosp-por-accidente']
                  )}
                />
              }
            />

            <SummaryItem
              name="coverages.227-coverage-hosp-por-enfermedad"
              desc="HOSPITALIZACIÓN POR ENFERMEDAD: 70€ por día hospitalizado"
              icon={<Fonendoskop alt="Hospitalización por Enfermedad icon" />}
              tooltip={
                <Tooltip
                  tooltipDesc={intl.formatMessage(
                    messages.tooltips['227-coverage-hosp-por-enfermedad']
                  )}
                />
              }
            />
          </div>

          <div className="my-8">
            <TitleText text="Además, añade coberturas complementarias" />
          </div>

          <div>
            <SummaryItem
              name="coverages.227-coverage-covalencia"
              title="CONVALECENCIA"
              desc="Si se alarga tu estancia en el hospital por encima de 14 días, recibirás una ayuda complementaria de 1.000€"
              icon={<Watch alt="CONVALECENCIA icon" />}
              tooltip={
                <Tooltip
                  tooltipDesc={intl.formatMessage(
                    messages.tooltips['227-coverage-covalencia']
                  )}
                />
              }
            />

            <SummaryItem
              name="coverages.227-coverage-trasporte"
              title="TRANSPORTE"
              desc="Sabemos que estar hospitalizado genera muchos gastos extra, por eso queremos ayudarte con los costes de desplazamiento y parking con 30€ al día adicionales"
              icon={<Transport alt="TRANSPORTE icon" />}
              tooltip={
                <Tooltip
                  tooltipDesc={intl.formatMessage(
                    messages.tooltips['227-coverage-trasporte']
                  )}
                />
              }
            />

            <SummaryItem
              name="coverages.227-coverage-familia"
              title="FAMILIA"
              desc="La familia es lo primero y queremos ayudarte a darle la atención que merece. Para ello nos hacemos cargo de los costes que se puedan generar (canguro, comidas fuera del hogar…) con 30€ al día adicionales."
              icon={<Family alt="FAMILIA icon" />}
              tooltip={
                <Tooltip
                  tooltipDesc={intl.formatMessage(
                    messages.tooltips['227-coverage-familia']
                  )}
                />
              }
            />
          </div>
        </div>

        <OrderSummary onPriceUpdateRequest={onPriceUpdateRequest} />
      </Form>
    </Formik>
  );
};

HospitalizationInsurance.propTypes = propTypes;

export default HospitalizationInsurance;
