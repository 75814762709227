import deporteSmall from '../../assets/Images/DEPORTE_320.gif';
import deporteLarge from '../../assets/Images/DEPORTE_1280.gif';
import hospitalizacionSmall from '../../assets/Images/HOSPITALIZACION_320.gif';
import hospitalizacionLarge from '../../assets/Images/HOSPITALIZACION_1280.gif';
import movilSmall from '../../assets/Images/MOVIL_320.gif';
import movilLarge from '../../assets/Images/MOVIL_1280.gif';

export const sport = {
  src: deporteLarge,
  srcSet: `${deporteSmall} 320w, ${deporteLarge}`,
};
export const hospitalization = {
  src: hospitalizacionLarge,
  srcSet: `${hospitalizacionSmall} 320w, ${hospitalizacionLarge}`,
};
export const mobile = {
  src: movilLarge,
  srcSet: `${movilSmall} 320w, ${movilLarge}`,
};
