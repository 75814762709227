export default (namespace) => ({
  titleThankYou: {
    id: `${namespace}.titleThankYou`,
    defaultMessage: `
			Ya hemos terminado.<br />
			¡Gracias por confiar en nosotros!
		`,
  },
  titleContinue: {
    id: `${namespace}.titleContinue`,
    defaultMessage: `
			¡Gracias por confiar en nosotros!
		`,
  },
  textThankYou: {
    id: `${namespace}.textThankYou`,
    defaultMessage: `
			¡Bienvenido!<br />
			Tu número de póliza es № {policy}.<br />
			Recibirás toda tu documentación en la dirección de e-mail que nos has facilitado. Para cualquier consulta puedes ponerte en contacto con nosotros llamando al 900 533 691.
		`,
  },
  textThankYouCarrefour: {
    id: `${namespace}.textThankYouCarrefour`,
    defaultMessage: `
			¡Bienvenido!<br />
			Tu número de póliza es № {policy}.<br />
			Recibirás toda tu documentación en la dirección de e-mail que nos has facilitado. Para cualquier consulta puedes ponerte en contacto con nosotros llamando al 900 533 698.
		`,
  },
  textContinue: {
    id: `${namespace}.textContinue`,
    defaultMessage: `
			¡Sólo nos queda un último paso!<br />
			Recibirás un link al email que nos has facilitado para descargarte una aplicación que verificará el estado de tu teléfono.<br />
			Una vez hayas realizado esta revisión, recibirás la documentación de tu seguro en la dirección de email que nos has facilitado.<br />
			Para cualquier consulta puedes ponerte en contacto con nosotros llamando al {policy}.
		`,
  },
  textContinueCarrefour: {
    id: `${namespace}.textContinueCarrefour`,
    defaultMessage: `
			¡Sólo nos queda un último paso!<br />
			Recibirás un link al email que nos has facilitado para descargarte una aplicación que verificará el estado de tu teléfono.<br />
			Una vez hayas realizado esta revisión, recibirás la documentación de tu seguro en la dirección de email que nos has facilitado.<br />
			Para cualquier consulta puedes ponerte en contacto con nosotros llamando al {policy}.
		`,
  },
});
