import React from 'react';
import { useIntl } from 'react-intl';

import FeatureCard from '../../../components/FeatureCard';
import {
  Benefit,
  Flexibility,
  WithoutPermanence,
} from '../../../components/Icons';
import { messages } from '../../intl';

const FeatureCards = () => {
  const intl = useIntl();

  return (
    <div className="mt-4 md:mt-6 lg:mt-8 xl:mt-12 grid md:gap-20 gap-2 sm:grid-cols-3 px-2">
      <FeatureCard
        icon={<Flexibility alt="flexibility icon" />}
        title={intl.formatMessage(messages.featureCards.flexibilityTitle)}
        text={intl.formatMessage(messages.featureCards.flexibilityText)}
      />
      <FeatureCard
        icon={<Benefit alt="benefits icon" />}
        title={intl.formatMessage(messages.featureCards.benefitsTitle)}
        text={intl.formatMessage(messages.featureCards.benefitsText)}
      />
      <FeatureCard
        icon={<WithoutPermanence alt="no permanence icon" />}
        title={intl.formatMessage(messages.featureCards.withoutPermanenceTitle)}
        text={intl.formatMessage(messages.featureCards.withoutPermanenceText)}
      />
    </div>
  );
};

export default FeatureCards;
