import React from 'react';
import cx from 'classnames';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Layout from '../modules/common/components/Layout';
import Container from '../components/Container';

const commonClasses = 'max-w-4xl mx-auto text-clr-black mb-12';
const h1Classes = '[&_h1]:text-5xl [&_h1]:text-center [&_h1]:my-8';
const h3Classes = '[&_h3]:text-2xl [&_h3]:font-bold [&_h3]:mb-4';
const pClasses = '[&_p]:text-sm [&_p]:mb-4';
const linkClasses = '[&_a]:text-clr-green';
const listClasses = '[&_ul]:ml-8 [&_ul]:list-disc [&_ul]:my-6';

const LegalPage = () => (
  <Layout variant="confirmation">
    <HelmetProvider>
      <Helmet>
        <title>CONDICIONES DE USO | Cetelem</title>
      </Helmet>
    </HelmetProvider>
    <p className="lis" />
    <Container>
      <main
        className={cx(
          commonClasses,
          h1Classes,
          h3Classes,
          pClasses,
          linkClasses,
          listClasses
        )}
      >
        <div>
          <div>
            <div>
              <h1>CONDICIONES DE USO</h1>
            </div>
          </div>
          <div>
            <div>
              <h3>INFORMACIÓN LEGAL</h3>
              <p>
                El presente Aviso Legal establece las condiciones generales (en
                adelante “Condiciones Generales”) que regulan el acceso, uso y
                navegación del presente sitio web (en adelante, “el sitio Web”)
                cuya titularidad ostenta CARDIF ASSURANCES RISQUES DIVERS,
                Sucursales en España (en adelante, “CARDIF”) con domicilio en C/
                Emilio Vargas n°4, 3ª planta, 28043, Madrid, y, con CIF
                W-0012420-F, e inscrita en el Registro Mercantil de Madrid, Tomo
                12.199, Folio 78, Sección 8ª, Hoja M-192922.
              </p>
              <p>
                CARDIF ha sido autorizada para operar en España, con Clave
                E-130, por la Dirección General de Seguros y Fondos de Pensiones
                (“DGSFP”). Este organismo, adscrito al Ministerio de Economía y
                Competitividad, es la autoridad competente para la supervisión
                en materia de seguros y reaseguros privados, capitalización y
                fondos de pensiones, revisando el cumplimiento por CARDIF de los
                requisitos precisos para el acceso y desarrollo de la actividad
                aseguradora y reaseguradora privada. Asimismo, la DGSFP lleva a
                cabo la supervisión ordinaria del ejercicio de la actividad y un
                control del cumplimiento de los requisitos exigibles a los
                administradores y socios de CARDIF, así como la resolución de
                las reclamaciones y consultas formuladas en materia de seguros y
                reaseguros privados, mediación en seguros privados y planes y
                fondos de pensiones.
              </p>
            </div>
          </div>
          <div>
            <div>
              <h3>CONDICIONES DE USO</h3>
              <p>
                El acceso, uso y navegación del sitio Web atribuye la condición
                de Usuario de la misma (en adelante, el «Usuario») e implica la
                aceptación plena y sin reservas de todas y cada una de las
                disposiciones incluidas en estas Condiciones Generales, en el
                momento mismo en que el Usuario acceda al sitio Web.
              </p>
              <p>
                CARDIF le informa de que el acceso y uso del sitio web y todos
                los subdominios y directorios incluidos bajo la misma (en
                adelante, conjuntamente denominados como “el sitio Web”), así
                como los servicios que a través de él se puedan obtener, están
                sujetos a los términos que se detallan en este Aviso Legal, sin
                perjuicio de que el acceso a alguno de dichos servicios pueda
                precisar de la aceptación de unas condiciones particulares
                propias (en adelante, las “Condiciones Particulares”) que, según
                los casos, sustituyen, completan y/o modifican las presentes
                Condiciones Generales. Por ello, si las consideraciones
                detalladas en este Aviso Legal no son de su conformidad, rogamos
                no haga uso del sitio Web, ya que cualquier uso que haga del
                mismo o de los servicios en él incluidos implicará la aceptación
                de los términos legales recogidos en este texto. A pesar de que,
                por la naturaleza de Internet, se pueda acceder al sitio Web
                desde cualquier parte del mundo, los contenidos del mismo van
                dirigidos única y exclusivamente a los residentes en España o,
                en su caso, a aquellas personas que puedan contratar los
                servicios del sitio Web desde España. Al solicitar la
                contratación de cualquier tipo de servicios ofrecidos en el
                sitio Web, el Usuario confirma pertenecer al mencionado ámbito.
                En este sentido, CARDIF se reserva el derecho de rechazar el
                alta como Cliente si tiene conocimiento de que el Usuario no
                cumple con los requisitos de contratación antes citados, o desde
                el momento en el que lo tenga.
              </p>
              <p>
                Las informaciones contenidas en este sitio Web tienen como única
                finalidad otorgar la posibilidad a los Usuarios de contratar los
                productos y servicios ofrecidos por el mismo, de conformidad con
                la normativa vigente en materia de seguros y bajo la supervisión
                de la DGSFP. CARDIF se reserva el derecho de modificar, eliminar
                o sustituir en cualquier momento el presente Aviso Legal o
                cualquiera de sus términos o condiciones. A estos efectos se
                considerará vigente y por lo tanto aplicable, el Aviso Legal que
                se encuentre a disposición de los usuarios en el sitio Web, por
                lo que le sugerimos que compruebe siempre la vigencia y
                exactitud de la información contenida en el mismo. Asimismo, el
                presente Aviso Legal se entenderá sin perjuicio de cualesquiera
                otras Condiciones Generales que regulen el acceso a bienes y
                servicios concretos dentro del sitio Web. CARDIF no se hace
                responsable de las informaciones que se pudieran contener en el
                sitio Web que procedan de fuentes ajenas al mismo.
              </p>
              <p>
                El sitio Web podría ofrecer determinados enlaces (links) con
                páginas de otras entidades, no asumiendo CARDIF ningún tipo de
                responsabilidad por la información o servicios que se pudieran
                ofrecer en páginas web de terceros.
              </p>
              <p>
                La información fiscal facilitada se ha elaborado de conformidad
                con la legislación tributaria vigente en la fecha de
                realización.
              </p>
            </div>
          </div>
          <div>
            <div>
              <h3>CARÁCTER DE LOS SERVICIOS</h3>
              <p>
                El acceso al presente sitio Web es gratuito. No obstante, la
                contratación de los productos o servicios que a través del sitio
                Web se ofrezcan puede estar sujeta a contraprestación económica.
              </p>
            </div>
          </div>
          <div>
            <div>
              <h3>PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>
              <p>
                CARDIF es el único titular y ostenta las correspondientes
                licencias y/o autorizaciones de los derechos de explotación de
                propiedad intelectual e industrial del sitio Web, así como del
                diseño, contenido, códigos logos, marcas, nombres comerciales o
                cualquier signo distintivo que aparecen en el mismo, software,
                programas informáticos o bases de datos que formen o hayan
                formado parte del mismo en algún momento, estando, por tanto,
                prohibida cualquier utilización de la misma que no tenga un
                carácter meramente informativo.
              </p>
              <p>
                En ningún caso se entenderá que el acceso y uso del sitio Web,
                implica la renuncia, transmisión, licencia o cesión total o
                parcial de dichos derechos.
              </p>
              <p>
                Asimismo está prohibido modificar, copiar, reutilizar, explotar,
                reproducir, comunicar públicamente, hacer segundas o posteriores
                publicaciones, cargar archivos, enviar por correo, transmitir,
                usar, tratar o distribuir de cualquier forma la totalidad o
                parte de los contenidos incluidos en el sitio Web para
                propósitos públicos o comerciales, si no se cuenta con la
                autorización expresa y por escrito de CARDIF o, en su caso, del
                titular de los derechos.
              </p>
            </div>
          </div>
          <div>
            <div>
              <h3>HIPERENLACES</h3>
              <p>
                CARDIF permite el establecimiento de hiperenlaces desde un sitio
                web ajeno, a cualquiera de las páginas del sitio Web de CARDIF
                previo cumplimiento de las siguientes condiciones:
              </p>
              <p>
                Los hiperenlaces que se encuentren en el sitio Web serán
                previamente consensuados con los titulares de las páginas webs
                enlazadas. CARDIF no será responsable del mal uso ni de las
                actividades contrarias a la ley, la moral o el orden público que
                realicen los usuarios en dichas páginas enlazadas.
              </p>
              <p>
                Cualquier hiperenlace se efectuará a la página principal del
                sitio Web.
              </p>
              <p>
                No se permite la reproducción ni total ni parcial de ninguno de
                los servicios contenidos en el sitio Web de CARDIF.
              </p>
              <p>
                No se incluirá ninguna manifestación falsa, inexacta o
                incorrecta sobre las páginas del sitio Web de CARDIF y sus
                servicios.
              </p>
              <p>
                CARDIF no se hace responsable de los contenidos a los que se
                acceda en virtud de los mencionados enlaces, ni de las
                modificaciones que se lleven a cabo en los mismos, ni del uso
                que de aquellos se realice, ni de su disponibilidad técnica.
              </p>
            </div>
          </div>
          <div>
            <div>
              <h3>RESPONSABILIDAD DE CARDIF</h3>
              <p>
                CARDIF no garantiza la disponibilidad y continuidad del
                funcionamiento del sitio Web y de los servicios. Cuando ello sea
                razonablemente posible, CARDIF, advertirá previamente de las
                interrupciones en el funcionamiento del sitio Web y de los
                servicios. Tampoco garantiza su infalibilidad y, en particular,
                aunque no de modo exclusivo, que los Usuarios puedan
                efectivamente utilizar el sitio Web y los servicios, acceder a
                las distintas páginas web que forman el sitio Web o a aquéllas
                desde las que se prestan los servicios.
              </p>
              <p>
                CARDIF excluye, con toda la extensión permitida por el
                ordenamiento jurídico, cualquier responsabilidad por los daños y
                perjuicios de toda naturaleza que puedan deberse a la falta de
                disponibilidad o de continuidad del funcionamiento del sitio Web
                y de los servicios, o la defraudación de la utilidad que los
                Usuarios hubieren podido atribuir al sitio Web y a los
                servicios, a la falibilidad del portal y de los servicios, y en
                particular, aunque no de modo exclusivo, a los fallos en el
                acceso a las distintas páginas web del sitio Web o a aquellas
                desde las que se prestan los servicios.
              </p>
              <p>
                CARDIF facilita todos los contenidos del sitio Web de buena fe y
                realizará sus mejores esfuerzos para que los mismos estén
                permanentemente actualizados y vigentes; no obstante, CARDIF no
                puede asumir responsabilidad alguna respecto al uso o acceso que
                realicen los Usuarios fuera del ámbito al que se dirige el sitio
                Web, ni de las consecuencias que pudiera acarrear la aplicación
                práctica de las opiniones, recomendaciones o estudios a que se
                pueda acceder a través del sitio Web, cuya responsabilidad final
                recaerá siempre sobre el Usuario. Asimismo, CARDIF no va a poder
                controlar los contenidos que no hayan sido elaborados por ella o
                por terceros cumpliendo su encargo y, por tanto, no responderá
                en ningún caso de los daños que pudieran causarse por dichos
                contenidos de terceros.
              </p>
              <p>
                CARDIF podrá incluir en el sitio Web publicidad de terceros para
                ofrecerle productos o servicios que entienda que pueden ser de
                su interés. Sin embargo, CARDIF no puede controlar la apariencia
                de dicha publicidad, ni la calidad y adecuación de dichos
                productos o servicios y, en consecuencia, CARDIF no responderá
                de ningún daño que se pudiera generar al Usuario por dichas
                causas. CARDIF, no garantiza la veracidad, exactitud,
                exhaustividad y actualidad de los contenidos publicitarios de
                terceros y, excluye cualquier responsabilidad por los daños y
                perjuicios de toda naturaleza que puedan deberse a la falta de
                veracidad, exactitud, exhaustividad y/o actualidad de los
                mismos.
              </p>
              <p>
                CARDIF se compromete a aplicar todas las medidas necesarias para
                intentar garantizar al Usuario la ausencia de virus, gusanos,
                caballos de Troya y elementos similares en el sitio Web. No
                obstante, estas medidas no son infalibles y, por ello, CARDIF no
                puede asegurar totalmente la ausencia de dichos elementos
                nocivos. En consecuencia, CARDIF no será responsable de los
                daños que los mismos pudieran producir al Usuario.
              </p>
              <p>
                CARDIF no puede garantizar la ausencia de fallos tecnológicos,
                ni la permanente disponibilidad del sitio Web y de los servicios
                contenidos en él y, en consecuencia, no asume responsabilidad
                alguna por los daños y perjuicios que puedan generarse por la
                falta de disponibilidad y por los fallos en el acceso
                ocasionados por desconexiones, averías, sobrecargas o caídas de
                la red no imputables a CARDIF.
              </p>
            </div>
          </div>
          <div>
            <div>
              <h3>RESPONSABILIDAD DEL USUARIO</h3>
              <p>
                El Usuario se compromete expresamente a hacer un uso adecuado de
                los contenidos y servicios que CARDIF ofrece o pueda ofrecer a
                través de su sitio web, teniendo especial relevancia los
                siguientes aspectos:
              </p>
              <p>
                El acceso al sitio Web y el uso correcto de la información
                contenida en el mismo son responsabilidad de quien realiza estas
                acciones, no siendo responsable CARDIF por el uso incorrecto,
                ilícito o negligente que del mismo pudiere hacer el Usuario.
              </p>
              <p>
                El Usuario se compromete a no vulnerar los derechos de propiedad
                intelectual o industrial, así como a no violar la
                confidencialidad de la información de CARDIF o de terceros.
              </p>
            </div>
          </div>
          <div>
            <div>
              <h3>RETIRADA Y SUSPENSIÓN DE LOS SERVICIOS</h3>
              <p>
                CARDIF podrá retirar o suspender en cualquier momento y sin
                necesidad de aviso previo la prestación de los servicios a
                aquellos Usuarios que incumplan lo establecido en las presentes
                Condiciones Generales.
              </p>
            </div>
          </div>
          <div>
            <div>
              <h3>DURACIÓN Y TERMINACIÓN</h3>
              <p>
                La prestación del servicio del sitio Web y de los demás
                servicios tiene, en principio, una duración indefinida. CARDIF,
                no obstante, está autorizada para dar por terminada o suspender
                la prestación del servicio del sitio Web y/o de cualquiera de
                los servicios en cualquier momento. Cuando ello sea
                razonablemente posible, CARDIF advertirá previamente la
                terminación o suspensión de la prestación del servicio del sitio
                Web y de los demás servicios.
              </p>
            </div>
          </div>
          <div>
            <div>
              <h3>NOTIFICACIONES</h3>
              <p>
                Todas las notificaciones y comunicaciones entre los Usuarios y
                CARDIF se considerarán eficaces, a todos los efectos, cuando se
                realicen a través de correo postal, correo electrónico o
                comunicación telefónica. Los usuarios deberán dirigirse a CARDIF
                mediante:
              </p>
              <ul>
                <li>
                  Envío por correo postal a la siguiente dirección: C/Emilio
                  Vargas n°4 3ª planta, 28043. Madrid.
                </li>
                <li>
                  Comunicación por medio de una llamada telefónica al número de
                  teléfono: 91 590 3005
                </li>
                <li>
                  Envío por correo electrónico a la siguiente dirección:
                  <a
                    href="mailto:atencioncliente@es.cardif.com"
                    title="atencioncliente@es.cardif.com"
                  >
                    atencioncliente@es.cardif.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <h3>NULIDAD E INEFICACIA DE LAS CLÁUSULAS</h3>
              <p>
                Si cualquier cláusula incluida en estas Condiciones Generales
                fuese declarada total o parcialmente nula o ineficaz, tal
                nulidad afectará tan sólo a dicha disposición o la parte de la
                misma que resulte nula o ineficaz, subsistiendo en todo lo demás
                el resto de las Condiciones Generales.
              </p>
            </div>
          </div>
          <div>
            <div>
              <h3>LEY APLICABLE Y JURISDICCIÓN</h3>
              <p>
                La ley aplicable en caso de disputa o conflicto de
                interpretación de los términos que conforman este Aviso Legal,
                así como cualquier cuestión relacionada con los servicios del
                presente sitio Web, será la ley española. Para la resolución de
                cualquier conflicto que pueda surgir con ocasión de la visita
                del sitio Web o del uso de los servicios que en él se puedan
                ofertar, CARDIF y el Usuario, con renuncia expresa del fuero que
                pudiera pertenecerles, acuerdan someterse a los Jueces y
                Tribunales de domicilio del Usuario, siempre que el mismo esté
                situado en territorio español.
              </p>
              <p>
                © 2019. Todos los derechos reservados CARDIF ASSURANCES RISQUES
                DIVERS, Sucursal en España.
              </p>
            </div>
          </div>
        </div>
      </main>
    </Container>
  </Layout>
);

export default LegalPage;
