import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { labelCommonClasses, labelWidthClass } from '../settings';

const propTypes = {
  text: PropTypes.string.isRequired,
};

const defaultProps = {};

const Label = ({ text }) => (
  <span className={cx(labelCommonClasses, labelWidthClass)}>{text}</span>
);

Label.propTypes = propTypes;
Label.defaultProps = defaultProps;

export default Label;
