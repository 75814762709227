import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Layout from '../modules/common/components/Layout';
import DownloadBar from '../modules/common/components/DownloadBar';
import WrapperBubbles from '../components/WrapperBubbles';
import HospitalizationInsurance from '../modules/form/HospitalizationInsurance';
import Container from '../components/Container';

const formVariant = 'hospitalization';

const HospitalizationInsurancePage = () => {
  const navigate = useNavigate();

  return (
    <Layout variant={formVariant}>
      <HelmetProvider>
        <Helmet>
          <title>Hospitalizacion contratacion | Cetelem</title>
        </Helmet>
      </HelmetProvider>
      <WrapperBubbles variant={formVariant}>
        <Container>
          <HospitalizationInsurance
            onSubmitSuccess={(formData) => {
              navigate('./contratacion', { state: { priceData: formData } });
            }}
          />
        </Container>
      </WrapperBubbles>
      <DownloadBar variant={formVariant} />
    </Layout>
  );
};

export default HospitalizationInsurancePage;
