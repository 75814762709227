import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import FlatPicker from 'react-flatpickr';
import MaskedInput from 'react-text-mask';
import { Spanish } from 'flatpickr/dist/l10n/es';

import './datepicker.css';
import TextField from '../TextField/TextField';

export const propTypes = {
  name: PropTypes.string.isRequired,
  locale: PropTypes.shape(),
  customOptions: PropTypes.shape(),
  label: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  value: PropTypes.string,
  showValid: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

const defaultProps = {
  customOptions: {},
  errorText: undefined,
  value: undefined,
  onBlur: undefined,
  showValid: 'initial',
  locale: Spanish,
};

const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

const MaskedDateField = (data) => (
  <MaskedInput
    mask={DATE_MASK}
    guide={false}
    autoComplete="off"
    placeholder="DD/MM/AAAA"
    type="text"
    data-input
    {...data}
  />
);

const DatePicker = ({
  name,
  label,
  showValid,
  errorText,
  locale,
  customOptions,
  onChange,
  onBlur,
  value,
  ...inputProps
}) => {
  const flatpickrRef = useRef(null);

  return (
    <div className="w-full">
      <FlatPicker
        ref={flatpickrRef}
        options={{
          locale,
          allowInput: true,
          wrap: true,
          dateFormat: 'd/m/Y',
          disableMobile: true,
          onReady: (dates, dateStr, instance) => {
            if (dateStr) {
              instance.input.dispatchEvent(
                new Event('input', { bubbles: true })
              );
            }
          },
          ...customOptions,
        }}
        onChange={([selectedDate]) => {
          if (selectedDate != null) {
            onChange(selectedDate.toISOString());
          } else {
            onChange(null);
          }
        }}
        onClose={onBlur}
      >
        <TextField
          {...inputProps}
          name={name}
          label={label}
          showValid={showValid}
          errorText={errorText}
          onChange={() => {}}
          value={value}
          inputComponent={MaskedDateField}
        />
      </FlatPicker>
    </div>
  );
};

DatePicker.propTypes = propTypes;
DatePicker.defaultProps = defaultProps;

export default DatePicker;
