import { test } from 'ramda';
import { createValidation } from '@validarium/core';

const nonDigitRegexp = /^[\D]*$/g;
const id = 'validarium.hasOnlyNonDigitCharacters';

const validateNonDigitCharacter = createValidation(
  (value) => test(nonDigitRegexp, value),
  { id }
);

export default validateNonDigitCharacter;
