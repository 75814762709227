import React from 'react';
import { useIntl } from 'react-intl';

import { messages } from '../../../intl';
import FormRow from '../components/FormRow';
import FormSection from '../components/FormSection';
import LabelLocalized from '../../components/LabelLocalized';
import TextInputField from '../../components/TextInputField';
import PhoneInputField from '../../components/PhoneInputField';

const propTypes = {};

const ContactDataSection = () => {
  const intl = useIntl();

  return (
    <FormSection
      title={intl.formatMessage(messages.widgetSubscription.contactTitle)}
    >
      <FormRow
        label={
          <LabelLocalized
            htmlFor="phone"
            message={messages.fieldLabels.phone}
          />
        }
        input={<PhoneInputField type="tel" id="phone" name="phone" />}
      />

      <FormRow
        label={
          <LabelLocalized
            isOptional
            htmlFor="otherPhone"
            message={messages.fieldLabels.otherPhone}
          />
        }
        input={<PhoneInputField type="tel" id="otherPhone" name="otherPhone" />}
      />

      <FormRow
        label={
          <LabelLocalized
            htmlFor="email"
            message={messages.fieldLabels.email}
          />
        }
        input={<TextInputField type="email" id="email" name="email" />}
      />
    </FormSection>
  );
};

ContactDataSection.propTypes = propTypes;

export default ContactDataSection;
