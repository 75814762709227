import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

const defaultProps = {};

const NavLink = ({ label, link, ...rest }) => (
  <a
    {...rest}
    className="text-xs text-clr-green hover:text-clr-green-dark leading-4 font-medium"
    href={link}
    target="_blank"
    rel="noreferrer"
  >
    {label}
  </a>
);

NavLink.propTypes = propTypes;
NavLink.defaultProps = defaultProps;

export default NavLink;
