import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import SubscriptionSummaryBox from './SubscriptionSummaryBox';
import SubscriptionSummaryTotal from './SubscriptionSummaryTotal';

const propTypes = {
  coverages: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  totalTitle: PropTypes.string.isRequired,
  totalPerMonth: PropTypes.string.isRequired,
  addTitle: PropTypes.string.isRequired,
  addPhone: PropTypes.string.isRequired,
};

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 1.0,
};

const SubscriptionSummary = ({
  coverages,
  title,
  addPhone,
  addTitle,
  ...totalProps
}) => {
  const containerRef = useRef();
  const [visible, setIsVisible] = useState(null);

  const onIntersect = useCallback(([entry]) => {
    setIsVisible(entry.isIntersecting);
  });

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef.current]);

  return (
    <>
      {!visible && <SubscriptionSummaryTotal {...totalProps} fixed />}
      <SubscriptionSummaryBox
        title={title}
        addPhone={addPhone}
        addTitle={addTitle}
        coverages={coverages}
      >
        <SubscriptionSummaryTotal {...totalProps} ref={containerRef} />
      </SubscriptionSummaryBox>
    </>
  );
};

SubscriptionSummary.propTypes = propTypes;

export default SubscriptionSummary;
