import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useIntl } from 'react-intl';

import CheckBoxDesign from '../../../components/Form/CheckBox';
import { getValidationState, maybeLocalizeError } from '../utils';

const propTypes = {
  name: PropTypes.string.isRequired,
};

const Checkbox = ({ name, ...rest }) => {
  const intl = useIntl();

  return (
    <Field name={name}>
      {({ field, meta, form }) => (
        <CheckBoxDesign
          {...rest}
          valueChecked={field.value}
          onChange={() => {
            form.setFieldTouched(name, true);
            form.setFieldValue(name, !field.value, true);
          }}
          errorText={
            getValidationState(meta) === 'invalid'
              ? maybeLocalizeError(intl, meta.error)
              : null
          }
          name={name}
        />
      )}
    </Field>
  );
};

Checkbox.propTypes = propTypes;

export default Checkbox;
