import React from 'react';

import ilcheck from '../../assets/Images/il-check.png';

const ILcheckImage = () => (
  <div className="flex justify-center w-full">
    <img src={ilcheck} alt="Cetelem" className="pb-5" />
  </div>
);

export default ILcheckImage;
