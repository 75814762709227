import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useIntl } from 'react-intl';

import RadioButtonDesign from '../../../components/Form/RadionButton';
import { getValidationState, maybeLocalizeError } from '../utils';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
};

const defaultProps = {
  label: undefined,
};

const RadioButton = ({ name, value, label }) => {
  const intl = useIntl();

  return (
    <Field type="radio" value={value} name={name}>
      {({ field, meta, form }) => (
        <RadioButtonDesign
          onChange={(e) =>
            e.target.checked && form.setFieldValue(field.name, value, true)
          }
          errorText={
            getValidationState(meta) === 'invalid'
              ? maybeLocalizeError(intl, meta.error)
              : null
          }
          label={label}
          name={field.name}
          value={value}
        />
      )}
    </Field>
  );
};

RadioButton.propTypes = propTypes;
RadioButton.defaultProps = defaultProps;

export default RadioButton;
