import { propEq, always, test, cond } from 'ramda';
import { createValidation } from '@validarium/core';

const resultMap = {
  0: 'T',
  1: 'R',
  2: 'W',
  3: 'A',
  4: 'G',
  5: 'M',
  6: 'Y',
  7: 'F',
  8: 'P',
  9: 'D',
  10: 'X',
  11: 'B',
  12: 'N',
  13: 'J',
  14: 'Z',
  15: 'S',
  16: 'Q',
  17: 'V',
  18: 'H',
  19: 'L',
  20: 'C',
  21: 'K',
  22: 'E',
};

// TODO Resolve nested document ID validation and document ID structure
const nifRegexp = /^\d{8}[A-Z]$/g;
const nieRegexp = /^[X-Z]\d{7}[A-Z]$/g;
const id = 'validarium.invalidDocumentId';

const nifModuloCheck = (documentId) =>
  documentId.replace(/^\d{8}/g, '') ===
  resultMap[parseInt(documentId.replace(/\D/g, ''), 10) % 23];

const nieModuloCheck = (documentId) =>
  documentId.replace(/^[X-Z]\d{7}/g, '') ===
  resultMap[
    parseInt(
      documentId
        .replace(/^[X]/g, '0')
        .replace(/^[Y]/g, '1')
        .replace(/^[Z]/g, '2')
        .replace(/\D/g, ''),
      10
    ) % 23
  ];

const isValidNif = createValidation(
  (documentId) => test(nifRegexp, documentId) && nifModuloCheck(documentId),
  { id }
);
const isValidNie = createValidation(
  (documentId) => test(nieRegexp, documentId) && nieModuloCheck(documentId),
  { id }
);

const validateDocumentId = cond([
  [propEq('personalIdNumberPrefix', 'NIF'), always(isValidNif)],
  [propEq('personalIdNumberPrefix', 'NIE'), always(isValidNie)],
]);

export default validateDocumentId;
