export default (namespace) => ({
  // OWNER & RENTER
  [`${namespace}.locationType`]:
    'Nos referimos con núcleo urbano al conjunto de edificaciones, constituido por un mínimo de 25 viviendas o 250 habitantes, con ayuntamiento situado dentro del mismo conjunto, y que posee todos los servicios de alumbrado, agua, alcantarillado y teléfono.',
  [`${namespace}.notCombustibleMaterials`]:
    'Si la construcción de tu vivienda está compuesta en su mayor parte por madera o paneles multicapa prefabricados en la estructura, cubierta y/o cerramientos, aunque disponga de revestimientos ornamentales de materiales incombustibles o tengan tratamiento ignífugo, la vivienda no puede ser asegurada.',
  [`${namespace}.notUninhabitedArea`]:
    'Entendemos que la vivienda está en un área deshabitada si se encuentra construida a más de un kilómetro de distancia de la urbanización o núcleo urbano más cercano.',
  [`${namespace}.insuranceStart`]: `
		<p>Dinos en qué fecha quieres que comience tu seguro de hogar.</p>
		<p>
			Si actualmente ya tienes un seguro de hogar, ten en cuenta el periodo que necesitas para
			darlo de baja.
		</p>
	`,
  [`${namespace}.totalPrice`]: `
		<p>
			Si eres propietario de una vivienda, te cubrimos el continente y el contenido, si eres
			inquilino te cubrimos el contenido (tus pertenencias).
		</p>
		<p>
			Si pudieses dar la vuelta a tu casa y ponerla boca abajo, todo lo que se cayese es el
			<strong>contenido</strong> (tus pertenencias), todo lo demás es el <strong>continente</strong>.
		</p>
	`,

  // OWNER
  [`${namespace}.225-coverage-basico`]: `
		<p>Protección ante:</p>
		<ul>
			<li>Incendios y explosiones</li>
			<li>Daños causados por agua o electricidad.</li>
			<li>Fenómenos atmosféricos</li>
			<li>Responsabilidad Civil</li>
			<li>Asistencia y Emergencia en el Hogar</li>
		</ul>
	`,
  [`${namespace}.225-coverage-danos-esteticos`]:
    'En caso de siniestro, TU SEGURO se hace cargo de que la estancia afectada quede tal y como lucía antes; y si no es posible, se hará cargo de restaurarla completamente para devolverle su estilo.',
  [`${namespace}.225-coverage-robo`]:
    'Nos encargamos de reparar los daños que hayan ocasionado para acceder a tu hogar, así como los bienes que te hayan robado.',
  [`${namespace}.225-coverage-roturas`]:
    'Te cubrimos las roturas accidentales de cristales, lunes, espejos, tableros de mármol, granito, placas vitrocerámicas y loza sanitaria, incluidos los gastos de instalación.',
  [`${namespace}.225-coverage-manitas`]: `
		<p>
			¿Cuántos Asegurados hacen falta para cambiar una bombilla? ¿Y para colgar un cuadro?
			NINGUNO. Llámanos y que lo haga un Manitas Profesional.
		</p>
		<p>
			Te proporcionamos ayuda profesional a domicilio, para realizar determinados trabajos de
			instalación, mantenimiento y adecuación del hogar.
		</p>
	`,

  // RENTER
  [`${namespace}.226-coverage-basico`]: `
		<p>Protección ante:</p>
		<ul>
			<li>Incendios y explosiones</li>
			<li>Daños causados por agua o electricidad</li>
			<li>Fenómenos atmosféricos</li>
			<li>Responsabilidad Civil</li>
			<li>Asistencia y Emergencia en el Hogar</li>
		</ul>
	`,
  [`${namespace}.226-coverage-robo`]:
    'Nos encargamos de reparar los daños que hayan ocasionado para acceder a tu hogar, así como los bienes que te hayan robado.',
  [`${namespace}.226-coverage-roturas`]:
    'Te cubrimos las roturas accidentales de cristales, lunes, espejos, tableros de mármol, granito, placas vitrocerámicas y loza sanitaria, incluidos los gastos de instalación.',
  [`${namespace}.226-coverage-manitas`]: `
		<p>
			¿Cuántos Asegurados hacen falta para cambiar una bombilla? ¿Y para colgar un cuadro?
			NINGUNO. Llámanos y que lo haga un Manitas Profesional.
		</p>
		<p>
			Te proporcionamos ayuda profesional a domicilio, para realizar determinados trabajos de
			instalación, mantenimiento y adecuación del hogar.
		</p>
	`,

  // HOSPITALIZATION
  [`${namespace}.227-coverage-hosp-por-accidente`]: `
		<p>
			En caso de Hospitalización a causa de un Accidente, recibirás una
			indemnización de 70€ por día hospitalizado, hasta un máximo de 30
			días consecutivos.
		</p>
		<p>Para poder acceder a la indemnización deberás estar hospitalizado al menos 24 horas.</p>
	`,
  [`${namespace}.227-coverage-hosp-por-enfermedad`]: `
		<p>
			En caso de Hospitalización a causa de una Enfermedad, recibirás una
			indemnización de 70€ por día hospitalizado, hasta un máximo de 30 días
			consecutivos.
		</p>
		<p>Para poder acceder a la indemnización deberás estar hospitalizado al menos 72 horas.</p>
	`,
  [`${namespace}.227-coverage-covalencia`]:
    'Para estancias superiores a 14 días, recibirás una indemnización de 1.000€ adicionales a la indemnización por hospitalización.',
  [`${namespace}.227-coverage-trasporte`]:
    'Recibirás una indemnización complementaria de 30€ al día destinada a cubrir los gastos de desplazamiento y parking que se puedan generar en caso de hospitalización.​',
  [`${namespace}.227-coverage-familia`]:
    'Recibirás una indemnización complementaria de 30€ al día destinada a cubrir los gastos familiares, como cuidado de niños y mayores, etc..  que se puedan generar en caso de hospitalización.',

  // IBAN
  [`${namespace}.bankAccount`]:
    'El IBAN es un código alfanumérico de 24 caracteres. Los dos primeros corresponden al país, los dos segundos son dígitos de control y a continuación el número de cuenta. Ejemplo de IBAN: ES12 1234 1234 12 1234567890',

  // MOBILE
  [`${namespace}.purchaseDate`]:
    'Solo podemos asegurar tu dispositivo si tiene menos de 6 meses de antigüedad. Solo podemos asegurar tu dispositivo si no es reacondicionado.',
  [`${namespace}.totalPriceMobile`]:
    'La franquicia es la cantidad que pagas al declarar un siniestro de tu dispositivo. Esta cantidad se utiliza para reducir el coste de tu seguro.',
  [`${namespace}.228-coverage-dano`]:
    'En caso de daño accidental de tu móvil te lo reemplazaremos por un terminal puesto a punto, de iguales o similares características técnicas.',
  [`${namespace}.228-coverage-recuerdos`]:
    'Te acompañamos en la creación de una copia de seguridad de tu teléfono y en su restauración en caso de robo o daño accidental, así podrás conservar todos tus recuerdos contigo.​',
  [`${namespace}.228-coverage-robo`]:
    'En caso de robo de tu móvil, te lo reemplazaremos por un terminal puesto a punto, de iguales o similares características técnicas.',

  // SPORt
  [`${namespace}.229-coverage-fallecimiento`]:
    'En caso de fallecimiento a consecuencia de un accidente mientras practicas deporte, tus herederos legales recibirán un capital de 15.000€',
  [`${namespace}.229-coverage-incapacidad-permanente`]:
    'En caso de Incapacidad Permanente a consecuencia de un accidente mientras practicas deporte, recibirás un capital de 15.000€',
  [`${namespace}.229-coverage-incapacidad-temporal`]:
    'Si sufres un accidente mientras practicas deporte y como consecuencia de éste tengas que estar en situación de baja laboral, recibirás una indemnización de 30€ por cada día que estés de baja, para que tu estilo de vida y el de los tuyos no se vea perjudicado. ',
  [`${namespace}.229-coverage-accesorios`]: `
		<p>
			Indemnización complementaria de 300€ destinada a cubrir los gastos de reposición de los accesorios que se hayan dañado en el accidente.
		</p>
		<p>
			Esta cobertura ha de ir ligada a la de Incapacidad Temporal, y su indemnización está sujeta a la aprobación de un siniestro por IT.
		</p>
	`,
});
