export default (namespace) => ({
  [`${namespace}.isRequired`]: 'Campo obligatorio',
  [`${namespace}.isEmail`]: 'Introduce tu email con . y @',
  [`${namespace}.isNumber`]: 'Tiene que ser un número',
  [`${namespace}.hasAgeInInterval`]:
    'Debe tener entre {minAge} y {maxAge} años',
  [`${namespace}.hasLength`]: 'Debe contener exactamente {length} caracteres',
  [`${namespace}.hasLengthMax`]: 'Número máximo de caracteres excedido ({max})',
  [`${namespace}.isPhoneNumber`]:
    'Asegúrate de introducir correctamente tu teléfono',
  [`${namespace}.hasValueInInterval`]: 'Debe estar entre {min} y {max}',
  [`${namespace}.invalidDocumentId`]: 'Número documento no válido',
  [`${namespace}.invalidPhoneNumber`]:
    'Asegúrate de introducir correctamente tu teléfono',
  [`${namespace}.hasOnlyNonDigitCharacters`]:
    'Debe contener solo caracteres alfabéticos',
  [`${namespace}.hasNoWhiteSpace`]: 'Debe contener solo caracteres alfabéticos',
  [`${namespace}.hasNoSpecialSymbols`]:
    'Debe contener solo caracteres alfabéticos',
  [`${namespace}.invalidName`]: 'Debe contener solo caracteres alfabéticos',
  [`${namespace}.invalidIbanWithSpaces`]: 'Número de cuenta no válido',
});
