import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { messages } from '../../../intl';
import FormRow from '../components/FormRow';
import FormSection from '../components/FormSection';
import LabelLocalized from '../../components/LabelLocalized';
import TextInputField from '../../components/TextInputField';
import DateOfBirthField from '../../components/DateOfBirthField';
import NifNieInputField from '../../components/NifNieInputField';

const propTypes = {
  maxAge: PropTypes.number,
};

const PersonalDataSection = ({ maxAge }) => {
  const intl = useIntl();

  return (
    <FormSection title={intl.formatMessage(messages.widgetSubscription.title)}>
      <FormRow
        label={
          <LabelLocalized
            htmlFor="givenName"
            message={messages.fieldLabels.givenName}
          />
        }
        input={<TextInputField id="givenName" name="givenName" />}
      />

      <FormRow
        label={
          <LabelLocalized
            htmlFor="surname"
            message={messages.fieldLabels.surname}
          />
        }
        input={<TextInputField id="surname" name="surname" />}
      />

      <FormRow
        label={
          <LabelLocalized
            htmlFor="secondSurname"
            message={messages.fieldLabels.secondSurname}
          />
        }
        input={<TextInputField id="secondSurname" name="secondSurname" />}
      />

      <FormRow
        label={
          <LabelLocalized
            htmlFor="personalIdNumber"
            message={messages.fieldLabels.personalIdNumber}
          />
        }
        input={<NifNieInputField id="personalIdNumber" />}
      />

      <FormRow
        label={
          <LabelLocalized
            htmlFor="birthDate"
            message={messages.fieldLabels.birthDate}
          />
        }
        input={
          <DateOfBirthField id="birthDate" name="birthDate" maxAge={maxAge} />
        }
      />
    </FormSection>
  );
};

PersonalDataSection.propTypes = propTypes;

export default PersonalDataSection;
