export default (namespace) => ({
  // OWNER & RENTER
  locationType: {
    id: `${namespace}.locationType`,
    defaultMessage:
      'We refer to the group of buildings, consisting of a minimum of 25 homes or 250 inhabitants, with a town hall located within the same complex, and which has all the lighting, water, sewage and telephone services.',
  },
  notCombustibleMaterials: {
    id: `${namespace}.notCombustibleMaterials`,
    defaultMessage:
      'If the construction of your home is mostly made of wood or multilayer panels prefabricated in the structure, roof and / or enclosures, even if it has ornamental coverings of fireproof materials or have fire retardant treatment, the house cannot be insured.',
  },
  notUninhabitedArea: {
    id: `${namespace}.notUninhabitedArea`,
    defaultMessage:
      'We understand that the house is in an uninhabited area if it is built more than a kilometer away from the nearest urbanization or urban center.',
  },
  insuranceStart: {
    id: `${namespace}.insuranceStart`,
    defaultMessage: `
				<p>Tell us on what date you want your home insurance to begin.</p>
				<p>If you currently have home insurance, consider the period you need to cancel it.</p>
			`,
  },
  totalPrice: {
    id: `${namespace}.totalPrice`,
    defaultMessage: `
			<p>
				Si eres propietario de una vivienda, te cubrimos el continente y el contenido, si eres
				inquilino te cubrimos el contenido (tus pertenencias).
			</p>
			<p>
				Si pudieses dar la vuelta a tu casa y ponerla boca abajo, todo lo que se cayese es el
				<strong>contenido</strong> (tus pertenencias), todo lo demás es el <strong>continente</strong>.
			</p>
		`,
  },

  // OWNER
  '225-coverage-basico': {
    id: `${namespace}.225-coverage-basico`,
    defaultMessage: `
			<p>Protección ante:</p>
			<ul>
				<li>Incendios y explosiones</li>
				<li>Daños causados por agua o electricidad.</li>
				<li>Fenómenos atmosféricos</li>
				<li>Responsabilidad Civil</li>
				<li>Asistencia y Emergencia en el Hogar</li>
			</ul>
		`,
  },
  '225-coverage-danos-esteticos': {
    id: `${namespace}.225-coverage-danos-esteticos`,
    defaultMessage:
      'En caso de siniestro, TU SEGURO se hace cargo de que la estancia afectada quede tal y como lucía antes; y si no es posible, se hará cargo de restaurarla completamente para devolverle su estilo.',
  },
  '225-coverage-robo': {
    id: `${namespace}.225-coverage-robo`,
    defaultMessage:
      'Nos encargamos de reparar los daños que hayan ocasionado para acceder a tu hogar, así como los bienes que te hayan robado.',
  },
  '225-coverage-roturas': {
    id: `${namespace}.225-coverage-roturas`,
    defaultMessage:
      'Te cubrimos las roturas accidentales de cristales, lunes, espejos, tableros de mármol, granito, placas vitrocerámicas y loza sanitaria, incluidos los gastos de instalación.',
  },
  '225-coverage-manitas': {
    id: `${namespace}.225-coverage-manitas`,
    defaultMessage: `
			<p>
				¿Cuántos Asegurados hacen falta para cambiar una bombilla? ¿Y para colgar un cuadro?
				NINGUNO. Llámanos y que lo haga un Manitas Profesional.
			</p>
			<p>
				Te proporcionamos ayuda profesional a domicilio, para realizar determinados trabajos de
				instalación, mantenimiento y adecuación del hogar.
			</p>
		`,
  },

  // RENTER
  '226-coverage-basico': {
    id: `${namespace}.226-coverage-basico`,
    defaultMessage: `
			<p>Protección ante:</p>
			<ul>
				<li>Incendios y explosiones</li>
				<li>Daños causados por agua o electricidad</li>
				<li>Fenómenos atmosféricos</li>
				<li>Responsabilidad Civil</li>
				<li>Asistencia y Emergencia en el Hogar</li>
			</ul>
		`,
  },
  '226-coverage-robo': {
    id: `${namespace}.226-coverage-robo`,
    defaultMessage:
      'Nos encargamos de reparar los daños que hayan ocasionado para acceder a tu hogar, así como los bienes que te hayan robado.',
  },
  '226-coverage-roturas': {
    id: `${namespace}.226-coverage-roturas`,
    defaultMessage:
      'Te cubrimos las roturas accidentales de cristales, lunes, espejos, tableros de mármol, granito, placas vitrocerámicas y loza sanitaria, incluidos los gastos de instalación.',
  },
  '226-coverage-manitas': {
    id: `${namespace}.226-coverage-manitas`,
    defaultMessage: `
			<p>
				¿Cuántos Asegurados hacen falta para cambiar una bombilla? ¿Y para colgar un cuadro?
				NINGUNO. Llámanos y que lo haga un Manitas Profesional.
			</p>
			<p>
				Te proporcionamos ayuda profesional a domicilio, para realizar determinados trabajos de
				instalación, mantenimiento y adecuación del hogar.
			</p>
		`,
  },

  // HOSPITALIZATION
  '227-coverage-hosp-por-accidente': {
    id: `${namespace}.227-coverage-hosp-por-accidente`,
    defaultMessage: `
			<p>
				En caso de Hospitalización a causa de un Accidente, recibirás una indemnización de 100€
				por día hospitalizado, hasta un máximo de 30 días consecutivos.
			</p>
			<p>Para poder acceder a la indemnización deberás estar hospitalizado al menos 24 horas.</p>
		`,
  },
  '227-coverage-hosp-por-enfermedad': {
    id: `${namespace}.227-coverage-hosp-por-enfermedad`,
    defaultMessage: `
			<p>
				En caso de Hospitalización a causa de una Enfermedad, recibirás una indemnización de 75€
				por día hospitalizado, hasta un máximo de 30 días consecutivos.
			</p>
			<p>Para poder acceder a la indemnización deberás estar hospitalizado al menos 72 horas.</p>
		`,
  },
  '227-coverage-covalencia': {
    id: `${namespace}.227-coverage-covalencia`,
    defaultMessage:
      'Para estancias superiores a 14 días, recibirás una indemnización de 1.000€ adicionales a la indemnización por hospitalización.',
  },
  '227-coverage-trasporte': {
    id: `${namespace}.227-coverage-trasporte`,
    defaultMessage:
      'Indemnización complementaria de 300€ destinada a cubrir los gastos de desplazamiento y parking que se puedan generar en caso de hospitalización.',
  },
  '227-coverage-familia': {
    id: `${namespace}.227-coverage-familia`,
    defaultMessage:
      'Indemnización complementaria de 300€ destinada a cubrir los gastos familiares, como cuidado de niños y mayores, etc..  que se puedan generar en caso de hospitalización.',
  },

  // IBAN
  bankAccount: {
    id: `${namespace}.bankAccount`,
    defaultMessage:
      'El IBAN es un código alfanumérico de 24 caracteres. Los dos primeros corresponden al país, los dos segundos son dígitos de control y a continuación el número de cuenta. Ejemplo de IBAN: ES12 1234 1234 12 1234567890',
  },

  // MOBILE
  purchaseDate: {
    id: `${namespace}.purchaseDate`,
    defaultMessage:
      'Solo podemos asegurar tu dispositivo si tiene menos de 6 meses de antigüedad. Solo podemos asegurar tu dispositivo si no es reacondicionado.',
  },
  totalPriceMobile: {
    id: `${namespace}.totalPriceMobile`,
    defaultMessage:
      'La franquicia es la cantidad que pagas al declarar un siniestro de tu dispositivo. Esta cantidad se utiliza para reducir el coste de tu seguro.',
  },
  '228-coverage-dano': {
    id: `${namespace}.228-coverage-dano`,
    defaultMessage:
      'En caso de daño accidental de tu móvil te lo reemplazaremos por un terminal puesto a punto, de iguales o similares características técnicas.',
  },
  '228-coverage-recuerdos': {
    id: `${namespace}.228-coverage-recuerdos`,
    defaultMessage:
      'Te acompañamos en la creación de una copia de seguridad de tu teléfono y en su restauración en caso de robo o daño accidental, así podrás conservar todos tus recuerdos contigo.',
  },
  '228-coverage-robo': {
    id: `${namespace}.228-coverage-robo`,
    defaultMessage:
      'En caso de robo de tu móvil, te lo reemplazaremos por un terminal puesto a punto, de iguales o similares características técnicas.',
  },

  // SPORT
  '229-coverage-fallecimiento': {
    id: `${namespace}.229-coverage-fallecimiento`,
    defaultMessage:
      'En caso de fallecimiento a consecuencia de un accidente mientras practicas deporte, tus herederos legales recibirán un capital de 15.000€',
  },
  '229-coverage-incapacidad-permanente': {
    id: `${namespace}.229-coverage-incapacidad-permanente`,
    defaultMessage:
      'En caso de Incapacidad Permanente a consecuencia de un accidente mientras practicas deporte, recibirás un capital de 15.000€',
  },
  '229-coverage-incapacidad-temporal': {
    id: `${namespace}.229-coverage-incapacidad-temporal`,
    defaultMessage:
      'Si sufres un accidente mientras practicas deporte y como consecuencia de éste tengas que estar en situación de baja laboral, recibirás una indemnización de 30€ por cada día que estés de baja, para que tu estilo de vida y el de los tuyos no se vea perjudicado. ',
  },
  '229-coverage-accesorios': {
    id: `${namespace}.229-coverage-accesorios`,
    defaultMessage: `
			<p>
				Indemnización complementaria de 300€ destinada a cubrir los gastos de reposición de los accesorios que se hayan dañado en el accidente.
			</p>
			<p>
				Esta cobertura ha de ir ligada a la de Incapacidad Temporal, y su indemnización está sujeta a la aprobación de un siniestro por IT.
			</p>
		`,
  },
  // CARREFOUR
  '234-coverage-fallecimiento': {
    id: `${namespace}.229-coverage-fallecimiento`,
    defaultMessage:
      'En caso de fallecimiento a consecuencia de un accidente mientras practicas deporte, tus herederos legales recibirán un capital de 15.000€',
  },
  '234-coverage-incapacidad-permanente': {
    id: `${namespace}.229-coverage-incapacidad-permanente`,
    defaultMessage:
      'En caso de Incapacidad Permanente a consecuencia de un accidente mientras practicas deporte, recibirás un capital de 15.000€',
  },
  '234-coverage-incapacidad-temporal': {
    id: `${namespace}.229-coverage-incapacidad-temporal`,
    defaultMessage:
      'Si sufres un accidente mientras practicas deporte y como consecuencia de éste tengas que estar en situación de baja laboral, recibirás una indemnización de 30€ por cada día que estés de baja, para que tu estilo de vida y el de los tuyos no se vea perjudicado. ',
  },
  '234-coverage-accesorios': {
    id: `${namespace}.229-coverage-accesorios`,
    defaultMessage: `
			<p>
				Indemnización complementaria de 300€ destinada a cubrir los gastos de reposición de los accesorios que se hayan dañado en el accidente.
			</p>
			<p>
				Esta cobertura ha de ir ligada a la de Incapacidad Temporal, y su indemnización está sujeta a la aprobación de un siniestro por IT.
			</p>
		`,
  },
});
