import { curryN, mergeRight } from 'ramda';

export const getValidationState = ({ touched, error }) => {
  if (!touched) return 'initial';
  if (error) return 'invalid';
  return 'valid';
};

export const maybeLocalizeError = curryN(2, (intl, error) => {
  if (typeof error === 'string') return error;
  if (error && error.id) return intl.formatMessage(error);

  return error;
});

export const addExtraFieldToPriceData = mergeRight;
