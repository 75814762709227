import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { messages } from '../../../intl';
import FormRow from '../components/FormRow';
import FormSection from '../components/FormSection';
import LabelLocalized from '../../components/LabelLocalized';
import TextInputField from '../../components/TextInputField';
import SelectBox from '../../components/SelectBox';
import { streetTypeOptions } from '../../_lundegaard/constants';

const ResidenceSection = () => {
  const intl = useIntl();
  const sto = useMemo(() => streetTypeOptions.map((v) => ({ value: v })), []);

  return (
    <FormSection
      title={intl.formatMessage(messages.widgetSubscription.residenceTitle)}
    >
      <FormRow
        label={
          <LabelLocalized
            htmlFor="streetType"
            message={messages.fieldLabels.streetType}
          />
        }
        input={
          <SelectBox
            className="capitalize"
            id="streetType"
            name="streetType"
            options={sto}
          />
        }
      />

      <FormRow
        label={
          <LabelLocalized
            htmlFor="street"
            message={messages.fieldLabels.street}
          />
        }
        input={<TextInputField id="street" name="street" />}
      />

      <FormRow
        label={
          <LabelLocalized
            htmlFor="number"
            message={messages.fieldLabels.number}
          />
        }
        input={<TextInputField type="tel" id="number" name="number" />}
      />

      <FormRow
        label={
          <LabelLocalized
            isOptional
            htmlFor="stairs"
            message={messages.fieldLabels.stairs}
          />
        }
        input={<TextInputField type="tel" id="stairs" name="stairs" />}
      />

      <FormRow
        label={
          <LabelLocalized
            isOptional
            htmlFor="floor"
            message={messages.fieldLabels.floor}
          />
        }
        input={<TextInputField id="floor" name="floor" />}
      />

      <FormRow
        label={
          <LabelLocalized
            isOptional
            htmlFor="letter"
            message={messages.fieldLabels.letter}
          />
        }
        input={<TextInputField id="letter" name="letter" />}
      />

      <FormRow
        label={
          <LabelLocalized
            htmlFor="postCode"
            message={messages.fieldLabels.postCode}
          />
        }
        input={<TextInputField type="tel" id="postCode" name="postCode" />}
      />

      <FormRow
        label={
          <LabelLocalized htmlFor="city" message={messages.fieldLabels.city} />
        }
        input={<TextInputField id="city" name="city" />}
      />

      <FormRow
        label={
          <LabelLocalized
            htmlFor="province"
            message={messages.fieldLabels.province}
          />
        }
        input={<TextInputField id="province" name="province" />}
      />

      <FormRow
        label={
          <LabelLocalized
            htmlFor="country"
            message={messages.fieldLabels.country}
          />
        }
        input={<TextInputField disabled id="country" name="country" />}
      />
    </FormSection>
  );
};

export default ResidenceSection;
