export default (namespace) => ({
  firstTitle: {
    id: `${namespace}.firstTitle`,
    defaultMessage: 'Build your insurance',
  },
  secondTitle: {
    id: `${namespace}.secondTitle`,
    defaultMessage: 'I want these protections',
  },
});
