export default (namespace) => ({
  isRequired: {
    id: `${namespace}.isRequired`,
    defaultMessage: 'This field is required',
  },
  isEmail: {
    id: `${namespace}.isEmail`,
    defaultMessage: 'Invalid e-mail address',
  },
  isNumber: {
    id: `${namespace}.isNumber`,
    defaultMessage: 'Must be a number',
  },
  hasLength: {
    id: `${namespace}.hasLength`,
    defaultMessage: 'Must contain exactly {length} characters',
  },
  hasLengthMax: {
    id: `${namespace}.hasLengthMax`,
    defaultMessage: 'Maximum number of characters exceeded ({max})',
  },
  isPhoneNumber: {
    id: `${namespace}.isPhoneNumber`,
    defaultMessage: 'Invalid phone number',
  },
  hasAgeInInterval: {
    id: `${namespace}.hasAgeInInterval`,
    defaultMessage: 'You must be between {minAge} and {maxAge} years old',
  },
  hasValueInInterval: {
    id: `${namespace}.hasValueInInterval`,
    defaultMessage: 'Must be between {min} and {max}',
  },
  invalidDocumentId: {
    id: `${namespace}.invalidDocumentId`,
    defaultMessage: 'Número documento no válido',
  },
  invalidPhoneNumber: {
    id: `${namespace}.invalidPhoneNumber`,
    defaultMessage: 'Asegúrate de introducir correctamente tu teléfono',
  },
  hasOnlyNonDigitCharacters: {
    id: `${namespace}.hasOnlyNonDigitCharacters`,
    defaultMessage: 'Must contain only alphabetical characters',
  },
  hasNoWhiteSpace: {
    id: `${namespace}.hasNoWhiteSpace`,
    defaultMessage: 'Must contain only alphabetical characters',
  },
  hasNoSpecialSymbols: {
    id: `${namespace}.hasNoSpecialSymbols`,
    defaultMessage: 'Must contain only alphabetical characters',
  },
  invalidName: {
    id: `${namespace}.invalidName`,
    defaultMessage: 'Must contain only alphabetical characters',
  },
  invalidIbanWithSpaces: {
    id: `${namespace}.invalidIbanWithSpaces`,
    defaultMessage: 'Must contain exactly 24 characters',
  },
});
