import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route } from 'react-router-dom';

import MobileInsurancePage from './pages/mobile';
import HospitalizationInsurancePage from './pages/hospitalization';
import SportInsurancePage from './pages/sport';
import SubscriptionPage from './pages/subscription';
import ConfirmationPage from './pages/confirmation';
import LegalPage from './pages/legal';
import SitemapPage from './pages/sitemap';

const PlaceholderPage = ({ title }) => (
  <div className="container mx-auto">
    <h1>Page {title}</h1>
  </div>
);

PlaceholderPage.propTypes = {
  title: PropTypes.string.isRequired,
};

const routes = (
  <>
    <Route path="hospitalizacion-cotizacion">
      <Route index path="" element={<HospitalizationInsurancePage />} />
      <Route path="contratacion" element={<SubscriptionPage />} />
      <Route path="confirmacion" element={<ConfirmationPage />} />
    </Route>
    <Route path="deporte-cotizacion">
      <Route index path="" element={<SportInsurancePage />} />
      <Route path="contratacion" element={<SubscriptionPage />} />
      <Route path="confirmacion" element={<ConfirmationPage />} />
    </Route>
    <Route path="movil-cotizacion">
      <Route index path="" element={<MobileInsurancePage />} />
      <Route path="contratacion" element={<SubscriptionPage />} />
      <Route path="confirmacion" element={<ConfirmationPage />} />
    </Route>
    <Route path="aviso-legal" element={<LegalPage />} />
    <Route path="mapa-web" element={<SitemapPage />} />
    <Route index element={<PlaceholderPage title="Not Found!" />} />
  </>
);

const Router = () => (
  <Routes>
    <Route path="/cetelem">{routes}</Route>
    <Route path="/">{routes}</Route>
    <Route path="*" element={<PlaceholderPage title="Not Found!" />} />
  </Routes>
);

export default Router;
