import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useIntl } from 'react-intl';

import DatePickerDesign from '../../../components/Form/DatePicker/DatePicker';
import LocalizedFormError from './LocalizedError';
import { getValidationState } from '../utils';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  legacyError: PropTypes.bool,
  maxAge: PropTypes.number,
};

const defaultProps = {
  label: '',
  legacyError: false,
};

const maybeDisplaylegacyError = (intl, ok, meta) => {
  if (!ok) return null;
  if (meta.touched && meta.error) return intl.formatMessage(meta.error);

  return null;
};

const DatePicker = ({ name, label, legacyError, maxAge, ...inputProps }) => {
  const intl = useIntl();

  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <div>
          <DatePickerDesign
            {...inputProps}
            label={label}
            name={field.name}
            value={field.value}
            onChange={(date) => form.setFieldValue(name, date, true)}
            onBlur={() => form.setFieldTouched(name, true, false)}
            errorText={maybeDisplaylegacyError(intl, legacyError, meta)}
            showValid={getValidationState(meta)}
          />
          {!legacyError && (
            <LocalizedFormError
              validationState={getValidationState(meta)}
              message={meta.error}
              maxAge={maxAge}
              field={field.name}
            />
          )}
        </div>
      )}
    </Field>
  );
};

DatePicker.propTypes = propTypes;
DatePicker.defaultProps = defaultProps;

export default DatePicker;
