import { createValidation } from '@validarium/core';
import { isValidIban, startsWith } from '@validarium/predicates';

const id = 'validarium.invalidIbanWithSpaces';

const isValidESIban = createValidation(
  (iban) => startsWith('ES', iban) && isValidIban(iban),
  {
    id,
  }
);

export default isValidESIban;
