export default (namespace) => ({
  [`${namespace}.status`]: 'Soy',
  [`${namespace}.owner`]: 'propietario',
  [`${namespace}.renter`]: 'inquilino',
  [`${namespace}.propertyType`]: 'de',
  [`${namespace}.house`]: 'una casa',
  [`${namespace}.floor`]: 'Piso',
  [`${namespace}.apartment`]: 'un piso',
  [`${namespace}.duplex`]: 'un dúplex',
  [`${namespace}.propertyArea`]: 'que tiene',
  [`${namespace}.locationType`]: 'Mi casa se encuentra en un núcleo urbano',
  [`${namespace}.notCombustibleMaterials`]:
    'Mi casa está construida en más de un 75% con materiales no combustibles',
  [`${namespace}.notUninhabitedArea`]:
    'Mi casa está ubicada en un área habitada',
  [`${namespace}.insuranceStart`]: 'Quiero que mi seguro de hogar comience el',
  [`${namespace}.yes`]: 'Si',
  [`${namespace}.no`]: 'No',
  [`${namespace}.isOptional`]: '&nbsp;(Opcional)',
  [`${namespace}.givenName`]: 'Nombre',
  [`${namespace}.surname`]: '1<sup>er</sup> apellido',
  [`${namespace}.secondSurname`]: '2º apellido',
  [`${namespace}.personalIdNumberPrefix`]: ' ',
  [`${namespace}.personalIdNumber`]: 'Número NIF/NIE',
  [`${namespace}.birthDate`]: 'Fecha de nacimiento',
  [`${namespace}.phone`]: 'Número de teléfono móvil',
  [`${namespace}.otherPhone`]: '2º teléfono',
  [`${namespace}.email`]: 'Email',
  [`${namespace}.emailOptional`]: 'Email (opcional)',
  [`${namespace}.streetType`]: 'Calle',
  [`${namespace}.street`]: 'Dirección',
  [`${namespace}.streetExtended`]: 'Dirección de la vivienda asegurada',
  [`${namespace}.number`]: 'Número',
  [`${namespace}.stairs`]: 'Escalera',
  [`${namespace}.letter`]: 'Puerta',
  [`${namespace}.postCode`]: 'Código Postal',
  [`${namespace}.city`]: 'Población',
  [`${namespace}.province`]: 'Provincia',
  [`${namespace}.country`]: 'País',
  [`${namespace}.bankAccount`]:
    'Introduce tu IBAN. Si lo deseas, lo puedes copiar y pegar.',
  [`${namespace}.marketingConsent`]:
    'Quiero recibir información sobre los productos y servicios del Grupo BNP Paribas que puedan resultar de mi interés, conforme a lo establecido en la Política de Privacidad​',
  [`${namespace}.licenceConsent`]: 'Acepto la {link}',
  [`${namespace}.gdprConsent`]:
    'Acepto la contratación del seguro de acuerdo a la información precontractual recibida​',
  [`${namespace}.period`]: 'Period',
  [`${namespace}.WEEK`]: 'Durante toda la semana (de Lunes a Domingo)',
  [`${namespace}.WEEKEND`]: 'Los fines de semana (de Viernes a Domingo)',
  [`${namespace}.purchasePrice`]: 'El precio de mi móvil está entre',
  [`${namespace}.PRICE_0_200`]: '0-200€',
  [`${namespace}.PRICE_200_500`]: '201€-500€',
  [`${namespace}.PRICE_500_750`]: '501€-750€',
  [`${namespace}.PRICE_750_1000`]: '751€-1000€',
  [`${namespace}.PRICE_1000`]: '1001€-1750€',
  [`${namespace}.purchaseDate`]: 'y lo compré el',
  [`${namespace}.acknowledgement`]:
    'Confirmo que los datos facilitados sobre mi teléfono móvil son correctos',
  [`${namespace}.calle`]: 'Calle',
  [`${namespace}.avenida`]: 'Avenida',
  [`${namespace}.plaza`]: 'Plaza',
  [`${namespace}.carretera`]: 'Carretera',
  [`${namespace}.paseo`]: 'Paseo',
  [`${namespace}.travesia`]: 'Travesia',
  [`${namespace}.contactName`]: 'Nombre y apellidos',
  [`${namespace}.contactDate`]: '¿Cuándo te gustaría contratar este seguro?',
  [`${namespace}.insuranceStatus`]: '¿Ya tienes este seguro con otra compañía?',
  [`${namespace}.insuranceNo`]: 'No',
  [`${namespace}.insuranceYes`]: 'Sí',
  [`${namespace}.date`]:
    '¿Cuándo te gustaría contratar este seguro? (opcional)',
});
