import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Text from '../Text';

const propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string,
  desc: PropTypes.string.isRequired,
  tooltip: PropTypes.node.isRequired,
  checkedValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const SummaryItem = ({
  icon,
  title,
  desc,
  tooltip,
  checkedValue,
  onChange,
  disabled,
}) => (
  <div className={cx('flex mb-6 md:mb-5 pr-[62px] md:pr-[72px] items-center')}>
    <div className="block place-self-center">
      <span className="hidden md:inline-block aspect-square h-[50px] w-[50px] xl:h-20 xl:w-20">
        {icon}
      </span>
    </div>
    <div className="w-full flex-row mx-3">
      {title && (
        <div className="block">
          <Text size="bodyDefault" text={title} className="inline-block" />
          <span className="inline-block align-sub mx-1.5">{tooltip}</span>
        </div>
      )}
      <div className="block">
        <Text
          size="bodyS"
          text={`${desc} ${disabled ? ' (obligatorio)' : ''}`}
          className="inline-block"
          color="darkGray"
        />
        {!title && (
          <span className="inline-block align-sub ml-1.5">{tooltip}</span>
        )}
      </div>
    </div>
    <div className="relative">
      <label className="cursor-pointer place-self-end md:absolute">
        <div className={`${title ? 'md:top-1/3' : ''}`}>
          <input
            disabled={disabled}
            type="checkbox"
            value={checkedValue}
            checked={checkedValue}
            onChange={onChange}
            className="peer sr-only"
          />
          <div className="w-12 h-6 opacity-20 bg-clr-green rounded-full peer-checked:opacity-100"></div>
          <div className="absolute flex items-center justify-center w-5 h-5 left-0.5 top-0.5 bg-white rounded-full transition peer-checked:translate-x-6 peer-checked:[&>*]:inline [&>*]:hidden">
            <span className="font-icons text-[10px] text-clr-green pt-px">
              &#xe902;
            </span>
          </div>
        </div>
      </label>
    </div>
  </div>
);

SummaryItem.propTypes = propTypes;

export default SummaryItem;
