import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

const Container = ({ children }) => (
  <div className="container mx-auto max-w-[1280px] px-2">{children}</div>
);

Container.propTypes = propTypes;

export default Container;
