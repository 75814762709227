export default (namespace) => ({
  total: {
    id: `${namespace}.total`,
    defaultMessage: 'Total',
  },
  month: {
    id: `${namespace}.month`,
    defaultMessage: '{value} / Month',
  },
  continent: {
    id: `${namespace}.continent`,
    defaultMessage: 'Continent: {value}',
  },
  content: {
    id: `${namespace}.content`,
    defaultMessage: 'Content: {value}',
  },
  contract: {
    id: `${namespace}.contract`,
    defaultMessage: 'Contract',
  },
  excess: {
    id: `${namespace}.excess`,
    defaultMessage: 'Excess: {value}',
  },
});
