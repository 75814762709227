import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import MaskedInput from 'react-text-mask';

import TextInput from '../../../components/FormV2/TextInput';
import LocalizedFormError from './LocalizedError';
import SelectBox from '../../../components/Form/SelectBox/SelectBox';
import { getValidationState } from '../utils';

const propTypes = {
  id: PropTypes.string,
};

const nifMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /[a-hA-Hj-nJ-Np-tP-Tv-zV-Z]/,
];
const nieMask = [
  /[x-zX-Z]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /[a-hA-Hj-nJ-Np-tP-Tv-zV-Z]/,
];

const NifNieText = ({ id }) => (
  <div>
    <div className="flex">
      <div className="w-[105px]">
        <Field name="personalIdNumberPrefix">
          {({ field }) => (
            <SelectBox
              {...field}
              options={[{ value: 'NIF' }, { value: 'NIE' }]}
            />
          )}
        </Field>
      </div>
      <div className="ml-5 grow">
        <Field name="personalIdNumber">
          {({ field, form, meta }) => (
            <MaskedInput
              {...field}
              id={id}
              guide={false}
              mask={
                form.values.personalIdNumberPrefix === 'NIE' ? nieMask : nifMask
              }
              render={(ref, props) => (
                <TextInput
                  {...props}
                  showStatusIcon
                  validationState={getValidationState(meta)}
                  ref={ref}
                />
              )}
            />
          )}
        </Field>
      </div>
    </div>
    <Field name="personalIdNumber">
      {({ meta }) => (
        <LocalizedFormError
          validationState={getValidationState(meta)}
          message={meta.error}
        />
      )}
    </Field>
  </div>
);

NifNieText.propTypes = propTypes;

export default NifNieText;
