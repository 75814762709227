export const BNP_PRIVACY_POLICY_URL =
  '/documents/politica-de-privacidad-bnp-paribas-grupo.pdf';
export const BNP_COOKIE_POLICY_URL = 'https://www.bnpparibascardif.es/cookies';
export const GATEWAY_SERVER_ADDR = '/gateway-server';
export const PRODUCT_SERVICE_ENDPOINT = `${GATEWAY_SERVER_ADDR}/product-service/api/v1`;
export const MOBILE_PRICE_ENDPOINT = `${PRODUCT_SERVICE_ENDPOINT}/products/228/price`;

export const MOBILE_DOWNLOAD_URL = '/documents/Mobile_06_2021.pdf';

export const HOSPI_DOWNLOAD_URL = '/documents/Hospitalization_06_2021.pdf';

export const SPORT_DOWNLOAD_URL = '/documents/Sports_06_2021.pdf';
