import React, { useEffect } from 'react';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import pathnameToProduct from '../modules/utils/pathnameToProduct';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import Container from '../components/Container';
import WrapperBubbles from '../components/WrapperBubbles';
import Layout from '../modules/common/components/Layout';
import SubscriptionForm from '../modules/form/Subscription/SubscriptionForm';

const SubscriptionPage = () => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  if (!state || !state.priceData) {
    return <Navigate to="../" />;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Layout variant="subscription">
      <HelmetProvider>
        <Helmet>
          <title>{pathnameToProduct(pathname)} contratacion | Cetelem</title>
        </Helmet>
      </HelmetProvider>
      <div className="my-4 max-w-md mx-auto">
        <Container>
          <ProgressBar width="half" />
        </Container>
      </div>
      <WrapperBubbles variant="subscription">
        <Container>
          <SubscriptionForm
            priceData={state.priceData}
            onSubmitSuccess={(confirmation) =>
              navigate('../confirmacion', { state: { confirmation } })
            }
          />
        </Container>
      </WrapperBubbles>
    </Layout>
  );
};

export default SubscriptionPage;
