export default (namespace) => ({
  [`${namespace}.header`]: '¿Te llamamos?',
  [`${namespace}.subtitle`]:
    'Si necesitas ayuda o quieres más información, rellena los siguientes datos y nos pondremos en contacto contigo ten rápido como podamos.',
  [`${namespace}.terms`]:
    'Consiento el tratamiento de mis datos para recibir información sobre estos seguros.',
  [`${namespace}.button`]: 'SÍ, LLAMADME GRATIS',
  [`${namespace}.complete`]:
    'Su solicitud de contacto se ha realizado correctamente. Le llamaremos lo antes posible. Gracias.',
  [`${namespace}.callButton`]: 'TE LLAMAMOS',
  [`${namespace}.header`]: '¿Te llamamos?',
  [`${namespace}.contacts1`]: 'O si lo prefieres llámanos al 900 533 691',
  [`${namespace}.contacts2`]:
    'Horario de atención: Lunes a Viernes de 9:00-20:00',
  [`${namespace}.moreInfo`]: 'Más información',
  [`${namespace}.error`]:
    'Ha ocurrido un error en su solicitud de contacto, por favor inténtelo de nuevo. Gracias',
});
