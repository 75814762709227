export default (namespace) => ({
  description: {
    '227-group-ademas': {
      id: `${namespace}.description.227-group-ademas`,
      defaultMessage: 'Además, añade coberturas complementarias',
    },
    '225-coverage-robo': {
      id: `${namespace}.description.225-coverage-robo`,
      defaultMessage:
        'Esperemos que nadie entre en tu casa sin tu permiso, pero si sucede, estarás protegido ante cualquier daño que puedan ocasionar y los objetos que puedan llevarse.',
    },
    '225-coverage-danos-esteticos': {
      id: `${namespace}.description.225-coverage-danos-esteticos`,
      defaultMessage:
        'La estética de tu casa es importante, por eso si se rompe una tubería y hay que pintar, mantendremos el estilo que has querido darle a tu hogar, ¡en toda la estancia!',
    },
    '225-coverage-roturas': {
      id: `${namespace}.description.225-coverage-roturas`,
      defaultMessage:
        'En caso de rotura de cristales, encimeras, loza y hasta la vitrocerámica de tu hogar, nos hacemos cargo de los gastos del remplazo, transporte e instalación.',
    },
    '225-coverage-manitas': {
      id: `${namespace}.description.225-coverage-manitas`,
      defaultMessage:
        'Colgar un cuadro, montar un mueble, colgar las cortinas nuevas… ¡Que lo haga otro! Ahorra tiempo y problemas llamando a un Manitas Profesional.',
    },
    '226-coverage-manitas': {
      id: `${namespace}.description.226-coverage-manitas`,
      defaultMessage:
        'Colgar un cuadro, montar un mueble, colgar las cortinas nuevas… ¡Que lo haga otro! Ahorra tiempo y problemas llamando a un Manitas Profesional.',
    },
    '226-coverage-robo': {
      id: `${namespace}.description.226-coverage-robo`,
      defaultMessage:
        'Esperemos que nadie entre en tu casa sin tu permiso, pero si sucede, estarás protegido ante cualquier daño que puedan ocasionar y los objetos que puedan llevarse.',
    },
    '226-coverage-roturas': {
      id: `${namespace}.description.226-coverage-roturas`,
      defaultMessage:
        'En caso de rotura de cristales, encimeras, loza y hasta la vitrocerámica de tu hogar, nos hacemos cargo de los gastos del remplazo, transporte e instalación.',
    },
    '227-coverage-familia': {
      id: `${namespace}.description.227-coverage-familia`,
      defaultMessage:
        'La familia es lo primero y queremos ayudarte a darle la atención que merece. Para ello nos hacemos cargo de los costes que se puedan generar (canguro, comidas fuera del hogar…) con 30€ al día adicionales.',
    },
    '227-coverage-hosp-por-enfermedad': {
      id: `${namespace}.description.227-coverage-hosp-por-enfermedad`,
      defaultMessage:
        'Hospitalización  por Enfermedad: 70€ por día hospitalizado',
    },
    '225-coverage-basico': {
      id: `${namespace}.description.225-coverage-basico`,
      defaultMessage:
        'Estarás protegido ante fuego, agua, electricidad y viento. Además contarás con las coberturas de Responsabilidad Civil y Asistencia y Emergencia en el hogar.',
    },
    '226-coverage-basico': {
      id: `${namespace}.description.226-coverage-basico`,
      defaultMessage:
        'Estarás protegido ante fuego, agua, electricidad y viento. Además contarás con las coberturas de Responsabilidad Civil y Asistencia y Emergencia en el hogar.',
    },
    '227-coverage-hosp-por-accidente': {
      id: `${namespace}.description.227-coverage-hosp-por-accidente`,
      defaultMessage:
        'Hospitalización  por Accidente : 70€ por día hospitalizado',
    },
    '228-coverage-recuerdos': {
      id: `${namespace}.description.228-coverage-recuerdos`,
      defaultMessage:
        '¿Por qué pasar horas intentando hacer que tu nuevo móvil sea como el antiguo? ¡Ahorra tiempo! Nosotros te ayudamos a recuperar tus contactos, fotos, aplicaciones...para que nada ni nadie estropee tus recuerdos.',
    },
    '228-coverage-robo': {
      id: `${namespace}.description.228-coverage-robo`,
      defaultMessage:
        'No te podemos prometer que atraparemos al ladrón, pero sí te garantizamos que tendrás un nuevo móvil.',
    },
    '229-coverage-incapacidad-permanente': {
      id: `${namespace}.description.229-coverage-incapacidad-permanente`,
      defaultMessage:
        'Protégete ante los imprevistos con un capital de 15.000€ que te ayudarán a mantener tu calidad de vida en caso de Incapacidad  Permanente.',
    },
    '229-coverage-accesorios': {
      id: `${namespace}.description.229-coverage-accesorios`,
      defaultMessage:
        'Reloj, pulsómetro, teléfono, tus nuevas deportivas ultraligeras... son muchos los accesorios que utilizamos para hacer deporte. ¡Protégelo todo! en caso de accidente recibirás una compensación de 300€ para que repongas lo que se dañe.',
    },
    '228-coverage-dano': {
      id: `${namespace}.description.228-coverage-dano`,
      defaultMessage:
        '¿Hay algo peor que quedarse sin móvil? Sustituiremos tu teléfono en caso de daño accidental o rotura de pantalla.',
    },
    '227-coverage-trasporte': {
      id: `${namespace}.description.227-coverage-trasporte`,
      defaultMessage:
        'Sabemos que estar hospitalizado genera muchos gastos extra, por eso queremos ayudarte con los costes de desplazamiento y parking con 30€ al día adicionales.',
    },
    '229-coverage-incapacidad-temporal': {
      id: `${namespace}.description.229-coverage-incapacidad-temporal`,
      defaultMessage:
        '¡Mantén tu ritmo de vida! Recibe una indemnización diaria de 30€ si por culpa de un accidente deportivo tienes que estar de baja laboral.',
    },
    '234-coverage-incapacidad-permanente': {
      id: `${namespace}.description.234-coverage-incapacidad-permanente`,
      defaultMessage:
        'Protégete ante los imprevistos con un capital de 15.000€ que te ayudarán a mantener tu calidad de vida en caso de Incapacidad  Permanente.',
    },
    '234-coverage-fallecimiento': {
      id: `${namespace}.description.234-coverage-fallecimiento`,
      defaultMessage:
        '15.000€ en caso de fallecimiento accidental, para que disfrutes de tu deporte con la mayor tranquilidad y responsabilidad.',
    },
    '234-coverage-accesorios': {
      id: `${namespace}.description.234-coverage-accesorios`,
      defaultMessage:
        'Reloj, pulsómetro, teléfono, tus nuevas deportivas ultraligeras... son muchos los accesorios que utilizamos para hacer deporte. ¡Protégelo todo! en caso de accidente recibirás una compensación de 300€ para que repongas lo que se dañe.',
    },
    '234-coverage-incapacidad-temporal': {
      id: `${namespace}.description.234-coverage-incapacidad-temporal`,
      defaultMessage:
        '¡Mantén tu ritmo de vida! Recibe una indemnización diaria de 30€ si por culpa de un accidente deportivo tienes que estar de baja laboral.',
    },
    '229-coverage-fallecimiento': {
      id: `${namespace}.description.229-coverage-fallecimiento`,
      defaultMessage:
        '15.000€ en caso de fallecimiento accidental, para que disfrutes de tu deporte con la mayor tranquilidad y responsabilidad.',
    },
    '227-coverage-covalencia': {
      id: `${namespace}.description.227-coverage-covalencia`,
      defaultMessage:
        'Si se alarga tu estancia en el hospital por encima de 14 días, recibirás una ayuda complementaria de 1.000€',
    },
  },
  title: {
    '227-group-ademas': {
      id: `${namespace}.title.227-group-ademas`,
      defaultMessage: '',
    },
    '225-coverage-robo': {
      id: `${namespace}.title.225-coverage-robo`,
      defaultMessage: 'Robo',
    },
    '225-coverage-danos-esteticos': {
      id: `${namespace}.title.225-coverage-danos-esteticos`,
      defaultMessage: 'Daños estéticos',
    },
    '225-coverage-roturas': {
      id: `${namespace}.title.225-coverage-roturas`,
      defaultMessage: 'Roturas',
    },
    '225-coverage-manitas': {
      id: `${namespace}.title.225-coverage-manitas`,
      defaultMessage: 'Manitas',
    },
    '226-coverage-manitas': {
      id: `${namespace}.title.226-coverage-manitas`,
      defaultMessage: 'Manitas',
    },
    '226-coverage-robo': {
      id: `${namespace}.title.226-coverage-robo`,
      defaultMessage: 'Robo',
    },
    '226-coverage-roturas': {
      id: `${namespace}.title.226-coverage-roturas`,
      defaultMessage: 'Roturas',
    },
    '227-coverage-familia': {
      id: `${namespace}.title.227-coverage-familia`,
      defaultMessage: 'Familia',
    },
    '227-coverage-hosp-por-enfermedad': {
      id: `${namespace}.title.227-coverage-hosp-por-enfermedad`,
      defaultMessage: '',
    },
    '225-coverage-basico': {
      id: `${namespace}.title.225-coverage-basico`,
      defaultMessage: 'Básico',
    },
    '226-coverage-basico': {
      id: `${namespace}.title.226-coverage-basico`,
      defaultMessage: 'Básico',
    },
    '227-coverage-hosp-por-accidente': {
      id: `${namespace}.title.227-coverage-hosp-por-accidente`,
      defaultMessage: '',
    },
    '228-coverage-recuerdos': {
      id: `${namespace}.title.228-coverage-recuerdos`,
      defaultMessage: 'Recuerdos',
    },
    '228-coverage-robo': {
      id: `${namespace}.title.228-coverage-robo`,
      defaultMessage: 'Robo',
    },
    '229-coverage-incapacidad-permanente': {
      id: `${namespace}.title.229-coverage-incapacidad-permanente`,
      defaultMessage: 'Incapacidad permanente accidental',
    },
    '229-coverage-accesorios': {
      id: `${namespace}.title.229-coverage-accesorios`,
      defaultMessage: 'Accesorios',
    },
    '228-coverage-dano': {
      id: `${namespace}.title.228-coverage-dano`,
      defaultMessage: 'Daño accidental',
    },
    '227-coverage-trasporte': {
      id: `${namespace}.title.227-coverage-trasporte`,
      defaultMessage: 'Transporte',
    },
    '229-coverage-incapacidad-temporal': {
      id: `${namespace}.title.229-coverage-incapacidad-temporal`,
      defaultMessage: 'Incapacidad temporal',
    },
    '234-coverage-incapacidad-permanente': {
      id: `${namespace}.title.234-coverage-incapacidad-permanente`,
      defaultMessage: 'Incapacidad permanente accidental',
    },
    '234-coverage-fallecimiento': {
      id: `${namespace}.title.234-coverage-fallecimiento`,
      defaultMessage: 'Fallecimiento accidental',
    },
    '234-coverage-accesorios': {
      id: `${namespace}.title.234-coverage-accesorios`,
      defaultMessage: 'Accesorios',
    },
    '234-coverage-incapacidad-temporal': {
      id: `${namespace}.title.234-coverage-incapacidad-temporal`,
      defaultMessage: 'Incapacidad temporal',
    },
    '229-coverage-fallecimiento': {
      id: `${namespace}.title.229-coverage-fallecimiento`,
      defaultMessage: 'Fallecimiento accidental',
    },
    '227-coverage-covalencia': {
      id: `${namespace}.title.227-coverage-covalencia`,
      defaultMessage: 'Convalecencia',
    },
  },
  titleSubscription: {
    '227-group-ademas': {
      id: `${namespace}.titleSubscription.227-group-ademas`,
      defaultMessage: '',
    },
    '225-coverage-robo': {
      id: `${namespace}.titleSubscription.225-coverage-robo`,
      defaultMessage: 'Robo',
    },
    '225-coverage-danos-esteticos': {
      id: `${namespace}.titleSubscription.225-coverage-danos-esteticos`,
      defaultMessage: 'Daños estéticos',
    },
    '225-coverage-roturas': {
      id: `${namespace}.titleSubscription.225-coverage-roturas`,
      defaultMessage: 'Roturas',
    },
    '225-coverage-manitas': {
      id: `${namespace}.titleSubscription.225-coverage-manitas`,
      defaultMessage: 'Manitas',
    },
    '226-coverage-manitas': {
      id: `${namespace}.titleSubscription.226-coverage-manitas`,
      defaultMessage: 'Manitas',
    },
    '226-coverage-robo': {
      id: `${namespace}.titleSubscription.226-coverage-robo`,
      defaultMessage: 'Robo',
    },
    '226-coverage-roturas': {
      id: `${namespace}.titleSubscription.226-coverage-roturas`,
      defaultMessage: 'Roturas',
    },
    '227-coverage-familia': {
      id: `${namespace}.titleSubscription.227-coverage-familia`,
      defaultMessage: 'Familia',
    },
    '227-coverage-hosp-por-enfermedad': {
      id: `${namespace}.titleSubscription.227-coverage-hosp-por-enfermedad`,
      defaultMessage: 'Hospitalización  por Enfermedad',
    },
    '225-coverage-basico': {
      id: `${namespace}.titleSubscription.225-coverage-basico`,
      defaultMessage: 'Básico',
    },
    '226-coverage-basico': {
      id: `${namespace}.titleSubscription.226-coverage-basico`,
      defaultMessage: 'Básico',
    },
    '227-coverage-hosp-por-accidente': {
      id: `${namespace}.titleSubscription.227-coverage-hosp-por-accidente`,
      defaultMessage: 'Hospitalización por Accidente',
    },
    '228-coverage-recuerdos': {
      id: `${namespace}.titleSubscription.228-coverage-recuerdos`,
      defaultMessage: 'Recuerdos',
    },
    '228-coverage-robo': {
      id: `${namespace}.titleSubscription.228-coverage-robo`,
      defaultMessage: 'Robo',
    },
    '229-coverage-incapacidad-permanente': {
      id: `${namespace}.titleSubscription.229-coverage-incapacidad-permanente`,
      defaultMessage: 'Incapacidad permanente accidental',
    },
    '229-coverage-accesorios': {
      id: `${namespace}.titleSubscription.229-coverage-accesorios`,
      defaultMessage: 'Accesorios',
    },
    '228-coverage-dano': {
      id: `${namespace}.titleSubscription.228-coverage-dano`,
      defaultMessage: 'Daño accidental',
    },
    '227-coverage-trasporte': {
      id: `${namespace}.titleSubscription.227-coverage-trasporte`,
      defaultMessage: 'Transporte',
    },
    '229-coverage-incapacidad-temporal': {
      id: `${namespace}.titleSubscription.229-coverage-incapacidad-temporal`,
      defaultMessage: 'Incapacidad temporal',
    },
    '234-coverage-incapacidad-permanente': {
      id: `${namespace}.titleSubscription.234-coverage-incapacidad-permanente`,
      defaultMessage: 'Incapacidad permanente accidental',
    },
    '234-coverage-fallecimiento': {
      id: `${namespace}.titleSubscription.234-coverage-fallecimiento`,
      defaultMessage: 'Fallecimiento accidental',
    },
    '234-coverage-accesorios': {
      id: `${namespace}.titleSubscription.234-coverage-accesorios`,
      defaultMessage: 'Accesorios',
    },
    '234-coverage-incapacidad-temporal': {
      id: `${namespace}.titleSubscription.234-coverage-incapacidad-temporal`,
      defaultMessage: 'Incapacidad temporal',
    },
    '229-coverage-fallecimiento': {
      id: `${namespace}.titleSubscription.229-coverage-fallecimiento`,
      defaultMessage: 'Fallecimiento accidental',
    },
    '227-coverage-covalencia': {
      id: `${namespace}.titleSubscription.227-coverage-covalencia`,
      defaultMessage: 'Convalecencia',
    },
  },
});
