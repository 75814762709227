import {
  MOBILE_INSURANCE_PRICE_URL,
  HOSPITALIZATION_INSURANCE_PRICE_URL,
  SPORT_INSURANCE_PRICE_URL,
  SUBSCRIBE_URL,
} from './config';

// Payload data
// ===============
// const data = {
//   productCode: 228,
//   purchasePrice: 'PRICE_0_200',
//   purchaseDate: '2022-12-01T00:00:00.000Z',
//   acknowledgement: true,
//   coverageCodes: ['228-coverage-dano'],
// };

export const queryMobileInsurancePrice = (data) =>
  fetch(MOBILE_INSURANCE_PRICE_URL, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());

export const queryHospitalizationInsurancePrice = (data) =>
  fetch(HOSPITALIZATION_INSURANCE_PRICE_URL, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());

export const querySportInsurancePrice = (data) =>
  fetch(SPORT_INSURANCE_PRICE_URL, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());

export const submitSubscription = (data) =>
  fetch(SUBSCRIBE_URL, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
