export const documentIdOptions = ['NIF', 'NIE'];

export const phonePrefixes = ['+34'];

export const streetTypeOptions = [
  'calle',
  'avenida',
  'plaza',
  'carretera',
  'paseo',
  'travesia',
];

export const initialValues = {
  givenName: '',
  surname: '',
  secondSurname: '',
  personalIdNumberPrefix: 'NIF',
  personalIdNumber: '',
  birthDate: '',
  phonePrefix: '+34',
  phone: '',
  otherPhonePrefix: '+34',
  otherPhone: '',
  email: '',
  street: '',
  streetType: 'calle',
  number: '',
  stairs: '',
  floor: '',
  letter: '',
  postCode: '',
  city: '',
  province: '',
  country: 'España',
  bankAccount: '',
  marketingConsent: false,
  licenceConsent: false,
  gdprConsent: false,
};
