import React from 'react';
import PropTypes from 'prop-types';

import FormRow from './FormRow';
import TitleText from '../../../../components/TitleText';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

const FormSection = ({ title, children }) => (
  <div className="mt-12">
    <FormRow input={<TitleText text={title} size="headerM" />} />
    {children}
  </div>
);

FormSection.propTypes = propTypes;

export default FormSection;
