import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  name: PropTypes.string.isRequired,
  validationState: PropTypes.oneOf(['valid', 'invalid', 'initial']),
  showStatusIcon: PropTypes.bool,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

const defaultProps = {
  id: undefined,
  validationState: 'initial',
  type: 'text',
  showStatusIcon: false,
  value: undefined,
  placeholder: undefined,
  onChange: undefined,
  onFocus: undefined,
  onBlur: undefined,
  disabled: false,
};

const TextInput = forwardRef(
  (
    {
      id,
      name,
      value,
      placeholder,
      validationState,
      showStatusIcon,
      onBlur,
      onChange,
      onFocus,
      type,
      disabled,
      ...rest
    },
    ref
  ) => (
    <div
      className={cx(
        'relative w-full h-fit',
        'after:font-icons after:text-[10px] after:pointer-events-none after:p-2 after:absolute',
        'after:right-1.5 after:top-1/2 after:-translate-y-1/2',
        {
          'after:content-[""] after:text-clr-green':
            validationState === 'valid' && showStatusIcon && value !== '',
          'after:content-[""] after:text-clr-red-error':
            validationState === 'invalid' && showStatusIcon,
        }
      )}
    >
      <input
        {...rest}
        id={id}
        disabled={disabled}
        name={name}
        type={type}
        ref={ref}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        className={cx(
          'text-sm leading-[22px] font-medium p-2.5 w-full border-2 rounded focus:ring-transparent',
          {
            'opacity-30': disabled,
            'border-clr-gray-light': validationState !== 'invalid',
            'border-clr-red-error': validationState === 'invalid',
            'focus:border-clr-green hover:border-clr-green':
              validationState !== 'invalid' && !disabled,
            'focus:border-clr-red-error hover:border-clr-red-error':
              validationState === 'invalid',
          },
          'transition-colors ease-in-out delay-150'
        )}
      />
    </div>
  )
);

TextInput.displayName = 'TextInput';
TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;

export default TextInput;
