import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  valueChecked: PropTypes.bool,
  errorText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

const defaultProps = {
  valueChecked: false,
};

const Checkbox = ({
  name,
  label,
  valueChecked,
  errorText,
  onChange,
  onBlur,
}) => {
  const textClasses = 'text-sm font-medium leading-[22px]';

  const cboxFocusClasses = 'focus:ring-transparent focus:text-clr-green';

  return (
    <>
      <div>
        <label
          className={cx(
            textClasses,
            'flex gap-4 pt-[5px] md:max-w-xl cursor-pointer select-none items-center'
          )}
        >
          <input
            type="checkbox"
            name={name}
            checked={valueChecked}
            onChange={onChange}
            onBlur={onBlur}
            className={cx(
              'block h-6 w-6 mt-1 border-2 text-clr-green cursor-pointer',
              cboxFocusClasses,
              {
                'border-clr-red-error focus:border-clr-red-error': errorText,
                'border-clr-gray-light': !errorText,
              }
            )}
          />
          <span>{label}</span>
        </label>
      </div>
      <div
        className={cx('pt-1 text-xs text-clr-red-error font-medium', {
          hidden: !errorText,
        })}
      >
        {errorText}
      </div>
    </>
  );
};

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
