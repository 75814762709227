import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import TextInput from '../TextInput';

const propTypes = {
  ...TextInput.propTypes,
  mask: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])
  ),
  guide: PropTypes.bool,
};

const defaultProps = {
  guide: false,
};

const MaskedTextInput = (props) => (
  <MaskedInput
    {...props}
    render={(ref, innerProps) => <TextInput {...innerProps} ref={ref} />}
  />
);

MaskedTextInput.propTypes = propTypes;
MaskedTextInput.defaultProps = defaultProps;

export default MaskedTextInput;
