export default (namespace) => ({
  header: {
    id: `${namespace}.header`,
    defaultMessage: '¿Te llamamos?',
  },
  subtitle: {
    id: `${namespace}.subtitle`,
    defaultMessage:
      'Si necesitas ayuda o quieres más información, rellena los siguientes datos y nos pondremos en contacto contigo ten rápido como podamos.',
  },
  terms: {
    id: `${namespace}.terms`,
    defaultMessage:
      'Consiento el tratamiento de mis datos para recibir información sobre estos seguros.',
  },
  button: {
    id: `${namespace}.button`,
    defaultMessage: 'SÍ, LLAMADME GRATIS',
  },
  complete: {
    id: `${namespace}.complete`,
    defaultMessage:
      'Su solicitud de contacto se ha realizado correctamente. Le llamaremos lo antes posible. Gracias.',
  },
  callButton: {
    id: `${namespace}.callButton`,
    defaultMessage: 'TE LLAMAMOS',
  },
  contacts1: {
    id: `${namespace}.contacts1`,
    defaultMessage: 'O si lo prefieres llámanos al 900 533 691',
  },
  contacts2: {
    id: `${namespace}.contacts2`,
    defaultMessage: 'atención: Lunes a Viernes de 9:00-20:00',
  },
  moreInfo: {
    id: `${namespace}.moreInfo`,
    defaultMessage: 'Más información',
  },
  error: {
    id: `${namespace}.error`,
    defaultMessage:
      'Ha ocurrido un error en su solicitud de contacto, por favor inténtelo de nuevo. Gracias',
  },
});
