/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, useFormikContext } from 'formik';

import Button from '../../../components/Button';
import SubscriptionSummary from '../../SubscriptionSummary';
import validator, { remapErrors } from '../_lundegaard/validator';
import { initialValues } from '../_lundegaard/constants';
import PersonalDataSection from './sections/PersonalDataSection';
import ContactDataSection from './sections/ContactDataSection';
import ResidenceSection from './sections/ResidenceSection';
import BankSection from './sections/BankSection';
import { toApiFormat } from './schema';
import { submitSubscription } from '../../api/products/service';

const propTypes = {
  priceData: PropTypes.shape().isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
};

const SubmitButton = () => {
  const { isSubmitting } = useFormikContext();

  return (
    <Button
      disabled={isSubmitting}
      label="Contrata online"
      type="submit"
      className="w-72 md:w-1/3"
    />
  );
};

const SubscriptionForm = ({ onSubmitSuccess, priceData }) => {
  const maxAge =
    priceData.productCode === '227'
      ? 49
      : priceData.productCode === '229'
      ? 59
      : 99;
  return (
    <Formik
      validateOnMount
      validateOnChange
      initialValues={initialValues}
      onSubmit={(values) =>
        submitSubscription(toApiFormat(priceData, values)).then(onSubmitSuccess)
      }
      validate={(values) =>
        remapErrors(validator(values, priceData.productCode))
      }
    >
      <Form>
        <div className="xl:flex">
          <div className="xl:w-[calc(((100%-430px)/2)+430px)] md:pr-[calc((100%-430px)/2)] xl:pr-0">
            <PersonalDataSection maxAge={maxAge} />
            <ContactDataSection />
            <ResidenceSection />
            <BankSection />
          </div>
          <div className="xl:w-[calc((100%-430px)/2)] text-center xl:text-right">
            <SubscriptionSummary
              totalPrice={priceData.totalPremium}
              coverages={priceData.coverages}
            />
          </div>
        </div>
        <div className="text-center my-8">
          <SubmitButton />
        </div>
      </Form>
    </Formik>
  );
};

SubscriptionForm.propTypes = propTypes;

export default SubscriptionForm;
