export default (namespace) => ({
  [`${namespace}.titleThankYou`]: `
		Ya hemos terminado.<br />
		¡Gracias por confiar en nosotros!
	`,
  [`${namespace}.titleContinue`]: `
		¡Gracias por confiar en nosotros!
	`,
  [`${namespace}.textThankYou`]: `
		¡Bienvenido!<br />
		Tu número de póliza es № {policy}.<br />
		Recibirás toda tu documentación en la dirección de e-mail que nos has facilitado. Para cualquier consulta puedes ponerte en contacto con nosotros llamando al 900 533 691.
	`,
  [`${namespace}.textThankYouCarrefour`]: `
		¡Bienvenido!<br />
		Tu número de póliza es № {policy}.<br />
		Recibirás toda tu documentación en la dirección de e-mail que nos has facilitado. Para cualquier consulta puedes ponerte en contacto con nosotros llamando al 900 533 698.
	`,
  [`${namespace}.textContinue`]: `
		¡Sólo nos queda un último paso!<br />
		Recibirás un link al email que nos has facilitado para descargarte una aplicación que verificará el estado de tu teléfono.<br />
		Una vez hayas realizado esta revisión, recibirás la documentación de tu seguro en la dirección de email que nos has facilitado.<br />
		Para cualquier consulta puedes ponerte en contacto con nosotros llamando al 900 533 691.
	`,
  [`${namespace}.textContinueCarrefour`]: `
		¡Sólo nos queda un último paso!<br />
		Recibirás un link al email que nos has facilitado para descargarte una aplicación que verificará el estado de tu teléfono.<br />
		Una vez hayas realizado esta revisión, recibirás la documentación de tu seguro en la dirección de email que nos has facilitado.<br />
		Para cualquier consulta puedes ponerte en contacto con nosotros llamando al 900 533 698.
	`,
});
