import React, { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { messages } from '../modules/intl';
import ProgressBar from '../components/ProgressBar';
import ILcheckImage from '../components/ILcheckImage';
import Layout from '../modules/common/components/Layout';
import Container from '../components/Container';
import pathnameToProduct from '../modules/utils/pathnameToProduct';

const ConfirmationPage = () => {
  const { state, pathname } = useLocation();
  const intl = useIntl();
  const shouldRedirect = useMemo(() => !state || !state.confirmation, []);
  const confirmation = useMemo(() => state && state.confirmation, []);

  if (shouldRedirect) {
    return <Navigate to="../" state={{}} />;
  }

  if (state && state.confirmation) {
    return <Navigate to="./" state={{}} replace />;
  }

  return (
    <Layout variant="confirmation">
      <HelmetProvider>
        <Helmet>
          <title>{pathnameToProduct(pathname)} confirmacion | Cetelem</title>
        </Helmet>
      </HelmetProvider>
      <Container>
        <div className="max-w-lg mx-auto my-8">
          <ProgressBar width="full" />
        </div>
        <div className="mt-12">
          <ILcheckImage />
        </div>
        <p
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              messages.widgetConfirmation.titleThankYou
            ),
          }}
          className="text-clr-green max-w-md mx-auto text-2xl font-bold text-center"
        />
        <p
          className="text-center text-clr-black text-xs font-medium max-w-xl mx-auto mt-8 mb-20"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              messages.widgetConfirmation.textThankYou,
              { policy: confirmation.policyNumber }
            ),
          }}
        />
      </Container>
    </Layout>
  );
};

export default ConfirmationPage;
