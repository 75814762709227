export default (namespace) => ({
  propertyArea: {
    id: `${namespace}.propertyArea`,
    defaultMessage: 'm2',
  },
  bankAccount: {
    id: `${namespace}.bankAccount`,
    defaultMessage: 'IBAN: ES12 1234 1234 12 1234567890',
  },
});
