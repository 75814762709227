import { test } from 'ramda';
import { createValidation } from '@validarium/core';

const phoneRegexp = /^[6-9]{1}\d{8}$/g;
const id = 'validarium.invalidPhoneNumber';

const validateOtherPhoneNumber = createValidation(
  (documentId) => test(phoneRegexp, documentId),
  {
    id,
  }
);

export default validateOtherPhoneNumber;
