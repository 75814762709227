import React from 'react';
import PropTypes from 'prop-types';

import TitleText from '../TitleText';
import Text from '../Text';

const propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const defaultProps = {};

const FeatureCard = ({ icon, title, text }) => (
  <div className="flex flex-row gap-4 item-start">
    <div className="w-8 md:w-12 lg:w-20 grid place-items-center shrink-0 pt-1">
      {icon}
    </div>
    <div className="max-w-400 grid content-center">
      <TitleText text={title} size="headerS" align="left" />
      <Text text={text} size="bodyS" color="darkGray" />
    </div>
  </div>
);

FeatureCard.propTypes = propTypes;
FeatureCard.defaultProps = defaultProps;

export default FeatureCard;
