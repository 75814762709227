/* eslint-disable no-nested-ternary */
import { combineValidate, validate } from '@validarium/core';
import {
  isRequired,
  hasLengthMax,
  isEmail,
  isNumber,
  hasLength,
  hasAgeInInterval,
} from '@validarium/validations';
import { fromPairs, lensIndex, map, over, pipe, prop, toPairs } from 'ramda';

import {
  validateDocumentId,
  validatePhoneNumber,
  validateOtherPhoneNumber,
  validateESIban,
  validateName,
} from './validators';
import {
  isProductHospitalizationCode,
  isProductSportCode,
} from './validators/constants';

const validations = ({ ...values }, productCode) =>
  validate({
    givenName: [isRequired, validateName, hasLengthMax(50)],
    surname: [isRequired, validateName, hasLengthMax(50)],
    secondSurname: [validateName, hasLengthMax(50)],
    personalIdNumberPrefix: [isRequired],
    personalIdNumber: [isRequired, validateDocumentId(values)],
    birthDate: [
      isRequired,
      isProductHospitalizationCode(productCode)
        ? hasAgeInInterval(18, 49)
        : isProductSportCode(productCode)
        ? hasAgeInInterval(18, 59)
        : hasAgeInInterval(18, 99),
    ],
    phone: [isRequired, validatePhoneNumber],
    otherPhone: [validateOtherPhoneNumber],
    email: [isRequired, isEmail, hasLengthMax(50)],
    streetType: [isRequired],
    street: [isRequired, hasLengthMax(50)],
    number: [isRequired, isNumber, hasLengthMax(10)],
    stairs: [isNumber, hasLengthMax(10)],
    floor: [isNumber, hasLengthMax(10)],
    letter: [hasLengthMax(10)],
    postCode: [isRequired, isNumber, hasLength(5)],
    city: [isRequired, validateName, hasLengthMax(50)],
    province: [isRequired, validateName, hasLengthMax(50)],
    country: [isRequired],
    bankAccount: [isRequired, validateESIban],
    licenceConsent: [isRequired],
    gdprConsent: [isRequired],
  })(values);

export default combineValidate(validations);

export const remapErrors = pipe(
  toPairs,
  map(over(lensIndex(1), prop('message'))),
  fromPairs
);
