import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  totalTitle: PropTypes.string.isRequired,
  totalPerMonth: PropTypes.string.isRequired,
  fixed: PropTypes.bool,
};

const defaultProps = {
  fixed: false,
};

const SubscriptionSummaryTotal = forwardRef(
  ({ fixed, totalPerMonth, totalTitle }, ref) => (
    <div
      ref={ref}
      className={cx('p-8 bg-clr-green text-left', {
        'fixed bottom-0 left-0 right-0 xl:hidden': fixed,
      })}
    >
      <p className="text-white text-sm leading-[22px] font-medium font-ubuntu">
        {totalTitle}
      </p>
      <p className="text-white text-xl lg:text-2xl lg:leading-[26px] leading-5 font-black font-ubuntu">
        {totalPerMonth}
      </p>
    </div>
  )
);

SubscriptionSummaryTotal.displayName = 'SubscriptionSummaryTotal';
SubscriptionSummaryTotal.propTypes = propTypes;
SubscriptionSummaryTotal.defaultProps = defaultProps;

export default SubscriptionSummaryTotal;
