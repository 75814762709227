import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const MissingDateValidator = () => {
  const { values, ...formik } = useFormikContext();

  useEffect(() => {
    if (values.acknowledgement) {
      formik.setFieldTouched('purchaseDate');
    }
    if (
      values.coverages['228-coverage-recuerdos'] ||
      values.coverages['228-coverage-robo']
    ) {
      formik.setFieldTouched('purchaseDate');
      formik.setFieldTouched('acknowledgement');
    }
  }, [values]);

  return null;
};

export default MissingDateValidator;
