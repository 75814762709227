import { GATEWAY_SERVER_ADDR } from '../../../constants';

const PRODUCTS_BASE_URL = `${GATEWAY_SERVER_ADDR}/product-service/api/v1/products`;
export const SUBSCRIBE_URL = `${GATEWAY_SERVER_ADDR}/subscription-request-service/api/v1/subscriptions`;

function createPriceUrl(pid) {
  return `${PRODUCTS_BASE_URL}/${pid}/price`;
}

function createCoveragesUrl(pid) {
  return `${PRODUCTS_BASE_URL}/${pid}/coverages`;
}

const PRODUCT_MOBILE_INSURANCE = '228';
const PRODUCT_HOSPITALIZATION_INSURANCE = '227';
const PRODUCT_SPORT_INSURANCE = '229';

export const MOBILE_INSURANCE_PRICE_URL = createPriceUrl(
  PRODUCT_MOBILE_INSURANCE
);
export const MOBILE_INSURANCE_COVERAGES_URL = createCoveragesUrl(
  PRODUCT_MOBILE_INSURANCE
);

export const HOSPITALIZATION_INSURANCE_PRICE_URL = createPriceUrl(
  PRODUCT_HOSPITALIZATION_INSURANCE
);
export const HOSPITALIZATION_INSURANCE_COVERAGES_URL = createCoveragesUrl(
  PRODUCT_HOSPITALIZATION_INSURANCE
);

export const SPORT_INSURANCE_PRICE_URL = createPriceUrl(
  PRODUCT_SPORT_INSURANCE
);
export const SPORT_INSURANCE_COVERAGES_URL = createCoveragesUrl(
  PRODUCT_SPORT_INSURANCE
);
