import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { usePopperTooltip } from 'react-popper-tooltip';

const propTypes = {
  tooltipDesc: PropTypes.string.isRequired,
};

const Tooltip = ({ tooltipDesc }) => {
  const [isVisible, setIsVisible] = useState(false);

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: 'hover',
      placement: 'top',
      closeOnOutsideClick: false,
      visible: isVisible,
      onVisibleChange: setIsVisible,
    });
  return (
    <div className="inline-block" ref={setTriggerRef}>
      <a className="font-icons text-clr-green text-[22px]"></a>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className:
              'tooltip-container w-72 px-5 py-3.5 bg-clr-gray-dark rounded text-white text-left text-sm',
          })}
          dangerouslySetInnerHTML={{ __html: tooltipDesc }}
        />
      )}
    </div>
  );
};

Tooltip.propTypes = propTypes;

export default Tooltip;
