import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  children: PropTypes.element.isRequired,
  variant: PropTypes.oneOf([
    'mobile',
    'sport',
    'hospitalization',
    'subscription',
  ]).isRequired,
};

const beforeClass =
  'before:content-[""] before:img-bubble before:img-bubble-left md:before:h-[162px] md:before:block before:hidden before:absolute before:left-0 before:w-[20%] ';
const beforeClassMD = 'lg:before:h-[325px] ';
const beforeClassXL = 'xl:before:h-[470px] ';

const beforeClassMobile = 'before:top-[200px]';
const beforeClassHospitalization = 'before:top-[60px]';

const afterClass =
  'after:content-[""] after:img-bubble after:img-bubble-right md:after:h-[162px] md:after:block after:hidden after:absolute after:right-0 after:w-[20%] ';
const afterClassMD = 'md:after:h-[268px] ';
const afterClassXL = 'lg:after:h-[355px]';
const afterClassMobile = 'after:top-[75px]';
const afterClassSubscription = 'after:top-[500px]';
const afterClassSport = 'after:top-[370px]';
const afterClassHospitalization = 'after:top-[350px]';

const WrapperBubbles = ({ children, variant }) => (
  <div
    className={cx(
      'relative flex justify-center z-1',
      beforeClass,
      beforeClassMD,
      beforeClassXL,
      afterClass,
      afterClassMD,
      afterClassXL,
      {
        [beforeClassMobile]: variant === 'mobile',
        [beforeClassHospitalization]: variant === 'hospitalization',
        [afterClassMobile]: variant === 'mobile',
        [afterClassSport]: variant === 'sport',
        [afterClassHospitalization]: variant === 'hospitalization',
        [afterClassSubscription]: variant === 'subscription',
      }
    )}
  >
    {children}
  </div>
);

WrapperBubbles.propTypes = propTypes;

export default WrapperBubbles;
