export default (namespace) => ({
  flexibilityTitle: {
    id: `${namespace}.flexibilityTitle`,
    defaultMessage: "It's upto you",
  },
  flexibilityText: {
    id: `${namespace}.flexibilityText`,
    defaultMessage: "Don't pay for coverage that you won't use.",
  },
  benefitsTitle: {
    id: `${namespace}.benefitsTitle`,
    defaultMessage: 'More than insurance',
  },
  benefitsText: {
    id: `${namespace}.benefitsText`,
    defaultMessage: 'Enjoy the benefits that we have designed for you.',
  },
  withoutPermanenceTitle: {
    id: `${namespace}.withoutPermanenceTitle`,
    defaultMessage: 'No Permanence',
  },
  withoutPermanenceText: {
    id: `${namespace}.withoutPermanenceText`,
    defaultMessage: 'You choose for how long, without penalties.',
  },
});
