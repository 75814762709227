import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import IntlProvider from './modules/intl';
import Router from './router';

const App = () => (
  <IntlProvider>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </IntlProvider>
);

export default App;
