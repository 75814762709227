import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import MaskedTextInput from '../../../components/FormV2/MaskedTextInput';
import { IBANMask } from '../../utils';
import { getValidationState } from '../utils';
import LocalizedFormError from './LocalizedError';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
};

const IBANField = ({ id, name }) => (
  <Field name={name}>
    {({ field, meta }) => (
      <div>
        <MaskedTextInput
          {...field}
          showStatusIcon
          guide={false}
          mask={IBANMask}
          id={id}
          validationState={getValidationState(meta)}
        />
        <div className="text-xs mt-1">IBAN: ES12 1234 1234 12 1234567890</div>
        <LocalizedFormError
          validationState={getValidationState(meta)}
          message={meta.error}
        />
      </div>
    )}
  </Field>
);

IBANField.propTypes = propTypes;

export default IBANField;
