export default (namespace) => ({
  firstTitle: {
    id: `${namespace}.firstTitle`,
    defaultMessage: 'Tell us about yourself',
  },
  secondTitle: {
    id: `${namespace}.secondTitle`,
    defaultMessage: 'Quiero estas coberturas',
  },
});
