import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  MOBILE_DOWNLOAD_URL,
  HOSPI_DOWNLOAD_URL,
  SPORT_DOWNLOAD_URL,
} from '../../../constants';
import GrayContainer from '../../../components/GrayContainer/GrayContainer';
import Button from '../../../components/Button';
import Text from '../../../components/Text';

const propTypes = {
  variant: PropTypes.oneOf(['mobile', 'hospitalization', 'sport']),
};

const DownloadBar = ({ variant }) => {
  const downloadUrl = useMemo(() => {
    switch (variant) {
      case 'mobile':
        return MOBILE_DOWNLOAD_URL;
      case 'hospitalization':
        return HOSPI_DOWNLOAD_URL;
      case 'sport':
      default:
        return SPORT_DOWNLOAD_URL;
    }
  }, [variant]);

  return (
    <GrayContainer>
      <div className="gap-4 py-10 flex flex-wrap justify-center items-center">
        <div className="inline-block">
          <Text
            text="Descarga aquí la información de tu seguro"
            size="subTitle"
            color="gray"
          />
        </div>
        <div>
          <Button
            label="Descarga"
            onClick={() => {
              window.open(downloadUrl);
            }}
            variant="outlined"
          />
        </div>
      </div>
    </GrayContainer>
  );
};

DownloadBar.propTypes = propTypes;

export default DownloadBar;
