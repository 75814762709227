import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';

import './index.css';
import App from './App';

const tagManagerArgsProd = {
  gtmId: 'GTM-WW9XB98',
};

const tagManagerArgsPreprod = {
  gtmId: 'GTM-5R7WGTR',
};

if (
  window.location.href.indexOf('preprod.contratacion.bnpparibascardif.es') > -1
)
  TagManager.initialize(tagManagerArgsPreprod);
else if (window.location.href.indexOf('contratacion.bnpparibascardif.es') > -1)
  TagManager.initialize(tagManagerArgsProd);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
