export default (namespace) => ({
  title: {
    id: `${namespace}.title`,
    defaultMessage: 'Personal information',
  },
  contactTitle: {
    id: `${namespace}.contactTitle`,
    defaultMessage: 'Contact',
  },
  residenceTitle: {
    id: `${namespace}.residenceTitle`,
    defaultMessage: 'Residence',
  },
  bankCodeTitle: {
    id: `${namespace}.bankCodeTitle`,
    defaultMessage: 'Please fill in your bank account',
  },
  bankCodeContent: {
    id: `${namespace}.bankCodeContent`,
    defaultMessage:
      'Le informamos de que sus datos serán tratados por Cardif para la contratación y gestión de su seguro, conforme a la cláusula de protección de datos del mismo y la Política de Privacidad',
  },
  confirmationTitle: {
    id: `${namespace}.confirmationTitle`,
    defaultMessage: 'Please confirm the following',
  },
  summaryBoxTitle: {
    id: `${namespace}.summaryBoxTitle`,
    defaultMessage: 'Your selection',
  },
  summaryBoxTotalTitle: {
    id: `${namespace}.summaryBoxTotalTitle`,
    defaultMessage: 'Total',
  },
  summaryBoxTotalPerMonth: {
    id: `${namespace}.summaryBoxTotalPerMonth`,
    defaultMessage: '{total} / Month',
  },
  summaryBoxAddTitle: {
    id: `${namespace}.summaryBoxAddTitle`,
    defaultMessage: 'Need help',
  },
  summaryBoxAddPhone: {
    id: `${namespace}.summaryBoxAddPhone`,
    defaultMessage: 'Please call us 900 533 691',
  },
  summaryBoxAddPhoneCarrefour: {
    id: `${namespace}.summaryBoxAddPhoneCarrefour`,
    defaultMessage: 'Please call us 900 533 698',
  },
});
