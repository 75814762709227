import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import OrderSummaryDesign from '../../../components/Form/OrderSummary';

const propTypes = {
  onPriceUpdateRequest: PropTypes.func.isRequired,
};

const OrderSummary = ({ onPriceUpdateRequest, ...rest }) => {
  const { submitForm, isValid, isValidating, isSubmitting, values } =
    useFormikContext();
  const [priceData, setPriceData] = useState({});

  const isError =
    (priceData.status === 404 || priceData.status === 400) &&
    priceData.code === '002';

  useEffect(() => {
    if ((isValid && !isValidating) || values.purchaseDate !== undefined) {
      onPriceUpdateRequest(values).then((res) => {
        setPriceData(res);
      });
    }
  }, [values, isValidating, isValid, isError]);

  return (
    <OrderSummaryDesign
      {...rest}
      priceData={priceData}
      buttonDisabled={!isValid || isSubmitting || isError}
      onButtonClick={() => submitForm()}
    />
  );
};

OrderSummary.propTypes = propTypes;

export default OrderSummary;
