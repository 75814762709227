import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import TextInput from '../../../components/FormV2/TextInput';
import { getValidationState } from '../utils';
import LocalizedFormError from './LocalizedError';

const propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
};

const TextInputField = ({ id, name, ...rest }) => (
  <Field name={name}>
    {({ field, meta }) => (
      <div>
        <TextInput
          {...rest}
          {...field}
          showStatusIcon
          id={id}
          validationState={getValidationState(meta)}
        />
        <LocalizedFormError
          validationState={getValidationState(meta)}
          message={meta.error}
          field={field.name}
        />
      </div>
    )}
  </Field>
);

TextInputField.propTypes = propTypes;

export default TextInputField;
