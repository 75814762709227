import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const GrayContainer = ({ children }) => (
  <div className="bg-clr-subtle-gray w-full px-2.5">{children}</div>
);

GrayContainer.propTypes = propTypes;

export default GrayContainer;
